// newsletter.js
import Layout from "components/layout/layout";
import ActivityCard from "components/activity_card/ActivityCard";
import "./wdc.css";

const wdcData = [
  {
    title: "Seminar on Career Opportunities in Technical World at Home School, Bhavnagar",
    description:
      "",
    images: ["/assets/wdc/a1i1.png","/assets/wdc/a1i2.png","/assets/wdc/a1i3.png"],
    report: "/assets/wdc/wdc1.pdf",
  },
  {
    title: "Seminar on Career Opportunities in Technical World at Takshshila Vidyalaya, Bhavnagar",
    description:
      "",
    images: ["/assets/wdc/a2i1.png","/assets/wdc/a2i2.png","/assets/wdc/a2i3.png"],
    report: "/assets/wdc/wdc2.pdf",
  },
  {
    title: "Workshop on Enlighten Within using SURYA NAMASKAR",
    description:
      "",
    images: ["/assets/wdc/a3i1.png","/assets/wdc/a3i2.png","/assets/wdc/wc.jpeg"],
    report: "/assets/wdc/wdc3.pdf",
  },
  {
    title: "Workshop on I dare to fight - A Workshop on Self Defense",
    description:
      "",
    images: ["/assets/wdc/a4i1.png","/assets/wdc/a4i2.png","/assets/wdc/a4i3.png"],
    report: "/assets/wdc/wdc4.pdf",
  },
  {
    title: "International Women's Day - 2022 Celebration",
    description:
      "",
    images: ["/assets/wdc/a5i1.png","/assets/wdc/a5i2.png"],
    report: "/assets/wdc/wdc5.pdf",
  },
  {
    title: "Seminar on Career Opportunities in Technical World at Muktalakshmi Mahila Vidhyalaya, Bhavnagar",
    description:
      "",
    images: ["/assets/wdc/a6i1.png","/assets/wdc/a6i2.png","/assets/wdc/a6i3.png","/assets/wdc/a6i4.png"],
    report: "/assets/wdc/wdc6.pdf",
  },
  {
    title: "Webinar on Garima - Gender Equality",
    description:
      "",
    images: ["/assets/wdc/wc.jpeg"],
    report: "/assets/wdc/wdc7.pdf",
  },
  {
    title: "Webinar on Women Empowerment - Fueling Growth",
    description:
      "",
    images: ["/assets/wdc/a8i1.png","/assets/wdc/a8i2.png"],
    report: "/assets/wdc/wdc8.pdf",
  },
  {
    title: "Webinar on Self Defense techniques using Karate",
    description:
      "",
    images: ["/assets/wdc/wc.jpeg"],
    report: "/assets/wdc/wdc9.pdf",
  },
  {
    title: "Expert Lecture on Food Habits and Women�s Health",
    description:
      "",
    images: ["/assets/wdc/a10i1.png","/assets/wdc/wc.jpeg"],
    report: "/assets/wdc/wdc10.pdf",
  },
];

const WDCPage= () => {
  return (
    <Layout
      title="Women Development Cell"
      breadcrumb={[
        { name: "Home", path: "/" },
        { name: "Women Development Cell" },
      ]}
    >
      <div className="coe-page">
        {/* Static Description */}
        <div className="coe-page-data">
          <div className="coe-about">
            <h3 className="coe-info-title">About Women Development Cell</h3>
    <p className="coe-desc">
      Women Development Cell is established to provide and maintain a dignified, congenial working environment for girl students and Women employees (including teaching and non-teaching staff) at GEC Bhavnagar, where they can study, work and explore their potential to the fullest address any grievances.
    </p>
    <h3 className="coe-info-title">Vision</h3>
    <p className="coe-desc">
       Maintaining and strengthening the status of Women and creating wider, gender sensitivity so as to facilitate a congenial working environment.
    </p>
    <h3 className="coe-info-title">Mission</h3>
    <p className="coe-desc">
       To create awareness of feminine potential and train women to acquire a wide range of skills and knowledge. To develop and increase their social, economic and intellectual capacities for peace, security and prosperity of mankind.
    </p>
    
    <h3 className="coe-info-title">Objectives</h3>
    <ul>
      <li>Organize awareness lectures/workshops to student/ women employees on different aspects of gender equality.</li>
      <li>Organize seminars, workshops relating to the overall personality development/self-defense of the girls and women at the institute.</li>
      <li>Arrange events in coordination with the other cells/clubs of the Institute or government or NGOs or as per the instructions of Higher authority.</li>
      <li>Take proactive steps towards health management of all students and women employee's members.</li>
      <li>The women development cell shall function to create an amicable working environment for the safety of the girl students and women employees at the workplace.</li>
    </ul>
    <h3 className="coe-info-title">Who can approach the WDC</h3>
    <ul>
      <li>Any employee including faculty, staff, contractual and student of Government Engineering College-Bhavnagar can approach the WDC.</li>
    </ul>
    <h2>Infrastructure/Facilities</h2>
    <ul>
      <li>A separate Girls Common Room has been given to girl students situated at room no.809.</li>
      <li>This room is equipped with the necessary infrastructure like first aid box, cupboards, chairs, tables and cots with mattress.</li>
      <li>One separate room is kept reserved for counselling of girl students.</li>
    </ul>
<h3 className="coe-info-title">Formation</h3>
<p className="coe-desc">
<table border="1" cellpadding="5" cellspacing="0">
  <thead>
    <tr>
      <th>Sr. No.</th>
      <th>Name</th>
      <th>Designation</th>
      <th>Department</th>
      <th>Role</th>
      <th>Email ID</th>
      <th>Contact Number</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1</td>
      <td>V. S. Dave</td>
      <td>Associate Professor</td>
      <td>Civil Engineering</td>
      <td>Convener</td>
      <td>vsdave.civil@gecbhavnagar.ac.in</td>
      <td>9998942010</td>
    </tr>
    <tr>
      <td>2</td>
      <td>B. S. Joshi</td>
      <td>Assistant Professor</td>
      <td>ICT Engineering</td>
      <td>Member</td>
      <td>bsjoshi.ict@gecbhavnagar.ac.in</td>
      <td>9428401949</td>
    </tr>
    <tr>
      <td>3</td>
      <td>Dr. A. Y. Pathak</td>
      <td>Assistant Professor</td>
      <td>Mechanical Engineering</td>
      <td>Member</td>
      <td>aypathak.mech@gecbhavnagar.ac.in</td>
      <td>9979537248</td>
    </tr>
    <tr>
      <td>4</td>
      <td>S. S. Machchar</td>
      <td>Assistant Professor</td>
      <td>Computer Engineering</td>
      <td>Member</td>
      <td>ssmachchar.ce@gecbhavnagar.ac.in</td>
      <td>9265220505</td>
    </tr>
    <tr>
      <td>5</td>
      <td>Palak Ravrani</td>
      <td>Student</td>
      <td>Electronics and Communication Department</td>
      <td>Student Coordinator</td>
      <td>-</td>
      <td>-</td>
    </tr>
    <tr>
      <td>6</td>
      <td>Tanna Sanjana C</td>
      <td>Student</td>
      <td>Information Technology Department</td>
      <td>Student Coordinator</td>
      <td>-</td>
      <td>-</td>
    </tr>
    <tr>
      <td>7</td>
      <td>Darshvi Sutaria</td>
      <td>Student</td>
      <td>Computer Engineering</td>
      <td>Student Coordinator</td>
      <td>-</td>
      <td>-</td>
    </tr>
    <tr>
      <td>8</td>
      <td>Bhavna Biswal</td>
      <td>Student</td>
      <td>Civil Engineering</td>
      <td>Student Coordinator</td>
      <td>-</td>
      <td>-</td>
    </tr>
    <tr>
      <td>9</td>
      <td>Chhatbar Shruti</td>
      <td>Student</td>
      <td>Information and Communication Technology Department</td>
      <td>Student Coordinator</td>
      <td>-</td>
      <td>-</td>
    </tr>
  </tbody>
</table>
</p>

  </div>
  
  </div>
        
        <div className="coe-labs" id="newsletters">
          <h3 className="coe-info-title">Activities</h3>
          <div className="coe-labs-list">
            <ActivityCard activities={wdcData} />
          </div>
        </div> 
      </div>
    </Layout>
  );
};

export default WDCPage;
