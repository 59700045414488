// newsletter.js
import Layout from "components/layout/layout";
import ActivityCard from "components/activity_card/ActivityCard";
import "./aboutcollege.css";

const newsletterData = [];

const AboutCollegePage = () => {
  return (
    <Layout
      title="About Government Engineering College, Bhavnagar"
      breadcrumb={[
        { name: "Home", path: "/" },
        { name: "About Government Engineering College, Bhavnagar" },
      ]}
    >
      <div className="coe-page">
        {/* Static Description */}
        <div className="coe-page-data">
          <div className="coe-about">
            <h3 className="coe-info-title">About Government Engineering College, Bhavnagar</h3>
            <p className="coe-desc">
             Founded in 2004, Government Engineering College (GEC),
Bhavnagar is administered by Commissionerate of Technical
Education, Government of Gujarat, recognized by the All India
Council of Technical Education (AICTE), New Delhi, and affiliated
with Gujarat Technological University (GTU), Ahmedabad. The
institute offers undergraduate engineering programmes in Civil
Engineering, Mechanical Engineering, Electronics and
Communication Engineering, Computer Engineering, Information
Technology and Information and Communication Technology, and a
postgraduate engineering programme in Electronics and
Communication Engineering. The institute has newly
constructed buildings and separate blocks for different
departments. This college is equipped with a resourceful library,
advanced laboratories, workshop, Scope-lab, Centre of Excellence,
Amenities, Hostels and other required infrastructure. Spread over
52,300 square meters of land, with 14,300 square meters dedicated
to building space, the campus beautifully blends green landscaping,
aesthetic sculptures, and a vibrant pursuit of knowledge fostered by
enthusiastic and well-qualified faculties. The college is constantly
striving for excellence in knowledge, skill-development, technology-
innovation and value-creation.
            </p>
            
            <h3 className="coe-info-title">VISION</h3>
            <p className="coe-desc">
             To transform the students into good human beings, employable engineering graduates and continuous learners by inculcating human values and imparting excellence in technical education.
            </p>
            
            <h3 className="coe-info-title">MISSION</h3>
            <p className="coe-desc">
             To impart education to rural and urban students, so as to earn respect from the society and thereby improving the living standards of their families and become asset for the industry and society. To foster a learning environment with technology integration and individual attention, so that the students imbibe quality technical knowledge, skill-development and character building.
            </p>
            
          </div>
        </div>
        
      </div>
    </Layout>
  );
};

export default AboutCollegePage;
