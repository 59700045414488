import "./team.css";
import Layout from "components/layout/layout";
import TeamCard from "./teamCard";
import { useState } from "react";

const Team = () => {

  const [selectedYear, setSelectedyear] = useState(2022);

  /* let members = [
    {
      name: "Malay Patoliya",
      dep: "Computer Engineering",
      batch: "2019",
      isCurrent: true,
      github: "https://github.com/malaypatoliya",
      linkedin: "",
      insta: "",
      twitter: "",
      uphold: 2022
    },
    {
      name: "Vikramsinh Parmar",
      dep: "Computer Engineering",
      batch: "2019",
      isCurrent: true,
      github: "https://github.com/Vikramsinh28",
      linkedin: "",
      insta: "",
      twitter: "",
      uphold: 2022
    },
    {
      name: "Milan Dudhat",
      dep: "Computer Engineering",
      batch: "2019",
      isCurrent: true,
      github: "https://github.com/milandudhat",
      linkedin: "",
      insta: "",
      twitter: "",
      uphold: 2022
    },
    {
      name: "Miten Gajjar",
      dep: "Computer Engineering",
      batch: "2017",
      isCurrent: true,
      github: "https://github.com/GajjarMiten",
      linkedin: "",
      insta: "",
      twitter: "",
      uphold: 2021
    },
    {
      name: "Taqi Jigar",
      dep: "Computer Engineering",
      batch: "2017",
      isCurrent: true,
      github: "https://github.com/taqi4",
      linkedin: "",
      insta: "",
      twitter: "",
      uphold: 2021
    },
    {
      name: "Brijrajsinh Parmar",
      dep: "Information Technology",
      batch: "2019",
      isCurrent: true,
      github: "https://github.com/brijrajparmar27",
      linkedin: "",
      insta: "",
      twitter: "",
      uphold: 2021
    },
    {
      name: "Shailee Shah",
      dep: "Information Technology",
      batch: "2020",
      isCurrent: true,
      github: "https://github.com/shailee2036",
      linkedin: "",
      insta: "",
      twitter: "",
      uphold: 2021
    },
  ];
 */

  const members = {
    2021: [
      {
        name: "Miten Gajjar",
        dep: "Computer Engineering",
        batch: "2022",
        isCurrent: true,
        github: "https://github.com/GajjarMiten",
        linkedin: "",
        insta: "",
        twitter: "",
        uphold: 2021
      },
      {
        name: "Taqi Jigar",
        dep: "Computer Engineering",
        batch: "2022",
        isCurrent: true,
        github: "https://github.com/taqi4",
        linkedin: "",
        insta: "",
        twitter: "",
        uphold: 2021
      },
      {
        name: "Brijrajsinh Parmar",
        dep: "Information Technology",
        batch: "2013",
        isCurrent: true,
        github: "", //https://github.com/brijrajparmar27
        linkedin: "",
        insta: "",
        twitter: "",
        uphold: 2021
      },
      {
        name: "Shailee Shah",
        dep: "Information Technology",
        batch: "2023",
        isCurrent: true,
        github: "https://github.com/shailee2036",
        linkedin: "",
        insta: "",
        twitter: "",
        uphold: 2021
      },
    ],
    2022: [
      {
        name: "Malay Patoliya",
        dep: "Computer Engineering",
        batch: "2023",
        isCurrent: true,
        github: "https://github.com/malaypatoliya",
        linkedin: "",
        insta: "",
        twitter: "",
        uphold: 2022
      },
      {
        name: "Vikramsinh Parmar",
        dep: "Computer Engineering",
        batch: "2023",
        isCurrent: true,
        github: "https://github.com/Vikramsinh28",
        linkedin: "",
        insta: "",
        twitter: "",
        uphold: 2022
      },
      {
        name: "Milan Dudhat",
        dep: "Computer Engineering",
        batch: "2023",
        isCurrent: true,
        github: "https://github.com/milandudhat",
        linkedin: "",
        insta: "",
        twitter: "",
        uphold: 2022
      },
    ]
  }
  return <div className="team">
    <Layout title="Team" />
    <h1 className="teamTitle">Developers</h1>
    <div className="team_contain">
      <div className="card_contain">
        {
          members[2021].map((member) => {
            return <TeamCard info={member} />
          })
        }
      </div>
    </div>
    <div className="teamTitle superset">
      <h1>Maintainers</h1>
      <div className="toogleYear">
        {
          Object.keys(members).map(year => {
            return <p onClick={() => { setSelectedyear(year) }} style={{ fontWeight: selectedYear == year ? "bold" : "unset" }}>{year}</p>
          })
        }
      </div>
    </div>
    <div className="team_contain">
      <div className="card_contain">
        {
          members[selectedYear].map((member) => {
            return <TeamCard info={member} />
          })
        }
      </div>
    </div>
  </div>;
};

export default Team;
