import React from 'react';
import Layout from 'components/layout/layout';
import './importantlinks.css'; // Create this file for custom styles

const ImportantLinks = () => {
  const links = [
    { name: 'Commissionerate of Technical Education, Gujarat', url: 'https://dte.gujarat.gov.in/' },
    { name: 'Gujarat Technological University', url: 'http://gtu.ac.in/' },
    { name: 'IIT Virtual Labs', url: 'https://www.vlab.co.in/' },
    { name: 'SWAYAM', url: 'https://swayam.gov.in/' },
    { name: 'NPTEL', url: 'https://nptel.ac.in/' },
    { name: 'Admission Committee for Professional Courses (ACPC), Gujarat', url: 'https://gujacpc.admissions.nic.in/' },
    { name: 'Admission Committee for Professional Diploma Courses (ACPDC), Gujarat', url: 'https://gujdiploma.admissions.nic.in/' },
  ];

  return (
    <div className="important-links">
      <Layout title="Important Links" />
      <h1 className="importantLinksTitle">Important Links</h1>
      <ul className="importantLinks">
        {links.map((link, index) => (
          <li key={index}>
            <a href={link.url} target="_blank" rel="noopener noreferrer">{link.name}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ImportantLinks;

