// newsletter.js
import Layout from "components/layout/layout";
import ActivityCard from "components/activity_card/ActivityCard";
import ImageSlider from "components/imageslider/ImageSlider";
import "./studentchapter.css";

const newsletterData = [
  {
    title: "Industrial visit to ACREX India 2024",
    description:
      "",
    images: ["/assets/studentchapter/a11i1.jpg","/assets/studentchapter/a11i2.jpg","/assets/studentchapter/a11i3.jpg","/assets/studentchapter/a11i4.jpg","/assets/studentchapter/a11i5.jpg","/assets/studentchapter/a11i6.jpg"],
    report: "/assets/studentchapter/ishrae11.pdf",
  },
  {
    title: "Quiz Competition at Gandhinagar Institute of Technology (GIT) Organized by ISHRAE Ahmedabad Chapter",
    description:
      "",
    images: ["/assets/studentchapter/a1i1.jpeg","/assets/studentchapter/a1i2.jpeg"],
    report: "/assets/studentchapter/ishrae1.pdf",
  },
  {
    title: "Pharma Connect with HVAC&R - Bridging the Gap Between Pharma & HVAC",
    description:
      "",
    images: ["/assets/studentchapter/a2i1.jpeg"],
    report: "/assets/studentchapter/ishrae2.pdf",
  },
  {
    title: "Industrial Visit to Ice Make Industry",
    description:
      "",
    images: ["/assets/studentchapter/a3i1.jpeg"],
    report: "/assets/studentchapter/ishrae3.pdf",
  },
  {
    title: "Revival of GEC Bhavnagar Students Chapter",
    description:
      "",
    images: ["/assets/studentchapter/a4i1.jpg","/assets/studentchapter/a4i2.jpg","/assets/studentchapter/a4i3.jpg","/assets/studentchapter/a4i4.jpg"],
    report: "/assets/studentchapter/ishrae4.pdf",
  },
  {
    title: "Quiz Competition at Tatyasaheb Kore Institute",
    description:
      "",
    images: ["/assets/studentchapter/a5i1.jpg","/assets/studentchapter/a5i2.jpg"],
    report: "/assets/studentchapter/ishrae5.pdf",
  },
  {
    title: "Industry Visit at Servottam Dairy (2023)",
    description:
      "",
    images: ["/assets/studentchapter/a6i1.jpeg","/assets/studentchapter/a6i2.jpg","/assets/studentchapter/a6i3.jpg","/assets/studentchapter/a6i4.jpg","/assets/studentchapter/a6i5.jpg","/assets/studentchapter/a6i6.jpeg","/assets/studentchapter/a6i7.jpeg"],
    report: "/assets/studentchapter/ishrae6.pdf",
  },
  {
    title: "Revival of GEC Bhavnagar Students Chapter 2023",
    description:
      "",
    images: ["/assets/studentchapter/a7i1.jpg","/assets/studentchapter/a7i2.jpg","/assets/studentchapter/a7i3.jpg","/assets/studentchapter/a7i4.jpg","/assets/studentchapter/a7i5.jpg","/assets/studentchapter/a7i6.jpg"],
    report: "/assets/studentchapter/ishrae7.pdf",
  },
  {
    title: "Industrial Visit to Synergy Agro Tech Pvt. Ltd",
    description:
      "",
    images: ["/assets/studentchapter/a8i1.jpg","/assets/studentchapter/a8i2.jpg"],
    report: "/assets/studentchapter/ishrae8.pdf",
  },
  {
    title: "Industry Visit at Servottam Dairy (2022)",
    description:
      "",
    images: ["/assets/studentchapter/a9i1.JPG","/assets/studentchapter/a9i2.JPG","/assets/studentchapter/a9i3.jpg"],
    report: "/assets/studentchapter/ishrae9.pdf",
  },
  {
    title: "14th ISHRAE CONFLUENCE",
    description:
      "",
    images: ["/assets/studentchapter/a10i1.jpeg","/assets/studentchapter/a10i2.jpeg"],
    report: "/assets/studentchapter/ishrae10.pdf",
  },

];

const NewsletterPage = () => {
const studentchapterimages = [
        '/assets/studentchapter/sli1.png',
        '/assets/studentchapter/sli2.jpeg',
        '/assets/studentchapter/sli3.jpeg',
        '/assets/studentchapter/sli4.jpeg',
        '/assets/studentchapter/sli5.jpeg',
    ];

  return (
    <Layout
      title="Student Chapter"
      breadcrumb={[
        { name: "Home", path: "/" },
        { name: "Student Chapter" },
      ]}
    >
      <div className="coe-page">
        {/* Static Description */}
        <div className="coe-page-data">
        <div className="library-slider">
                    <ImageSlider images={studentchapterimages} />
                </div>
          <div className="coe-about">
            <h3 className="coe-info-title">About ISHRAE Bhavnagar Chapter</h3>
            <p className="coe-desc">
              The ISHRAE Student Chapter at Government Engineering College, Bhavnagar, was established in 2021 to enhance students' technical knowledge and industry exposure in HVAC&R (Heating, Ventilation, Air Conditioning, and Refrigeration). The chapter organizes expert lectures, industrial visits, workshops, and hands-on training to bridge the gap between academia and industry. It provides networking opportunities, career guidance, and access to national-level events like ACREX and ISHRAE Job Junction. Through continuous learning and industry collaboration, the chapter aims to develop skilled professionals in the field of HVAC&R.
            </p>
          </div>
        </div>
        {/* Activity Cards Displaying Newsletter Items */}
        <div className="coe-labs" id="newsletters">
          <h3 className="coe-info-title">Activities</h3>
          <div className="coe-labs-list">
            <ActivityCard activities={newsletterData} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NewsletterPage;
