import React, { useEffect, useState } from "react";
import Sidebar from "components/placement/sidebar/sidebar";
import "./placement-styles/summary.css";
import DataProvider from "hoc/data_provider";
import Layout from "components/layout/layout";
import Heading from "components/heading/Heading";
const cards = [
    {
        icon: "fa fa-user",
        title: "Sanctioned Intake",
        value: "rs",
    },
    {
        icon: "fa fa-briefcase",
        title: "Placed Students",
        value: "ps",
    },
    {
        icon: "fa fa-building",
        title: "Placement Rate",
        value: "pr",
    },
    {
        icon: "fa fa-user",
        title: "Highest Package",
        value: "hp",
    },
    {
        icon: "fa fa-briefcase",
        title: "Average Package",
        value: "ap",
    },
    {
        icon: "fa fa-building",
        title: "Companies Visited",
        value: "cv",
    },
];

const Summary = () => {
    return (
        <DataProvider
            component={Summaries}
            endPoint="/web/placements/summaries"
        />
    );
};
export default Summary;

const currentYear = new Date().getFullYear();
const Summaries = ({ data }) => {
    const [isActive, setIsActive] = useState(
        //new Date().getFullYear().toString()
        (currentYear - 1).toString()
    );
    let mx = 0;
    data.forEach((summary) => {
        mx = Math.max(mx, parseInt(summary.year));
    });
    const [stats, setStats] = useState({
        maxYear: 0,
        rs: 0,
        ps: 0,
        pr: 0,
        hp: 0,
        ap: 0,
        cv: 0,
    });

    const calcStats = () => {
        let d = {
            maxYear: mx,
            rs: 0,
            ps: 0,
            pr: 0,
            hp: 0,
            ap: 0,
            cv: 0,
        };
        data.forEach((summary) => {
            if (summary.year === isActive) {
                d.rs += parseInt(summary.registeredStudents);
                d.ps += parseInt(summary.placedStudents);
                d.hp = Math.max(d.hp, parseFloat(summary.highestPackage));
                d.ap += parseFloat(summary.averagePackage);
                d.cv += parseInt(summary.companies);
            }
        });
        d.pr = ((d.ps / d.rs) * 100).toFixed(2) + "%";
        d.hp = (d.hp).toFixed(2) + "Lacs";
        d.ap = (d.ap / 6.0).toFixed(2) + "Lacs";
        setStats(d);
    };

    const filtredSummary = data.filter((summary) => summary.year === isActive);

    useEffect(() => {
        calcStats();
    }, [isActive]);

    return (
        <Layout
            title="Summary of Placements"
            breadcrumb={[
                {
                    name: "Home",
                    path: "/",
                },
                {
                    name: "Summary of Placements",
                },
            ]}
        >
            <div className="summary placement">
                <div className="for-division">
                    <div className="content">
                        <div className="years">
                            {Array.from({ length: 3 }, (i, idx) => {
                                return (
                                    <div
                                        className={
                                            parseInt(isActive) ===
                                                stats.maxYear - idx
                                                ? "active year"
                                                : "year"
                                        }
                                        onClick={() =>
                                            setIsActive(
                                                (stats.maxYear - idx).toString()
                                            )
                                        }
                                    >
                                        {stats.maxYear - idx}
                                    </div>
                                );
                            })}
                        </div>
                        {stats.pr === "NaN%" ? (
                            <div>No Data Available</div>
                        ) : (
                            <>
                                <div className="cards">
                                    {cards.map((card) => {
                                        return (
                                            <DataCard
                                                title={card.title}
                                                icon={card.icon}
                                                value={stats[card.value]}
                                            />
                                        );
                                    })}
                                </div>
                                <Heading>Branch Wise Summary (As on 31st December of Respective Year)</Heading>
                                <div className="statistics-table">
                                    <table className="stats">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Branch</th>
                                                <th>Sanctioned Intake</th>
                                                <th>Placed Students</th>
                                                <th>Placed %</th>
                                                <th>Highest package</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filtredSummary.map(
                                                (summary, idx) => {
                                                    return (
                                                        <DataRow
                                                            index={idx + 1}
                                                            summary={summary}
                                                        />
                                                    );
                                                }
                                            )}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th> </th>
                                                <th>Total</th>
                                                <th>{stats.rs}</th>
                                                <th>{stats.ps}</th>
                                                <th>{stats.pr}</th>
                                                <th></th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </>
                        )}
                    </div>
                    <Sidebar />
                </div>
            </div>
        </Layout>
    );
};

const DataCard = ({ icon, title, value }) => {
    return (
        <div className="data-card">
            <div className="card-icon">
                <i className={icon}></i>
            </div>
            <div className="card-info">
                <strong>{value}</strong>
                <p>{title}</p>
            </div>
        </div>
    );
};

const DataRow = ({ index, summary }) => {
    const {
        departmentName,
        registeredStudents,
        placedStudents,
        highestPackage,
    } = summary;

    return (
        <tr>
            <td>{index}</td>
            <td>{departmentName}</td>
            <td>{registeredStudents}</td>
            <td>{placedStudents}</td>
            <td>
                {(
                    (parseInt(placedStudents) / parseInt(registeredStudents)) *
                    100
                ).toFixed(2) + "%"}
            </td>
            <td>{(highestPackage / 1).toFixed(2) + " Lacs"}</td>
        </tr>
    );
};
 
