// newsletter.js
import Layout from "components/layout/layout";
import ActivityCard from "components/activity_card/ActivityCard";
import "./iil.css";

const iilData = [
  {
    title: "workshop on �Skill4Future : Python, ML & AI� by Edunet Foundation",
    description:
      "",
    images: ["/assets/iil/a5i1.png","/assets/iil/a5i2.png","/assets/iil/a5i3.png"],
    report: "/assets/iil/iil5.pdf",
  },
  {
    title: "Five Days Workshop on \"AI-Saksham: Applied Cloud Computing for Software Development\"",
    description:
      "",
    images: ["/assets/iil/a1i1.jpg","/assets/iil/a1i2.jpg"],
    report: "/assets/iil/iil1.pdf",
  },
  {
    title: "Industrial visit at Sparrow Softtech, Bhavnagar",
    description:
      "",
    images: ["/assets/iil/a2i1.jpeg","/assets/iil/a2i2.jpeg","/assets/iil/a2i3.jpeg"],
    report: "/assets/iil/iil2.pdf",
  },
  {
    title: "Expert lecture on \"Expectation of Industries from Academia\"",
    description:
      "",
    images: ["/assets/iil/a3i1.jpg","/assets/iil/a3i2.jpg"],
    report: "/assets/iil/iil3.pdf",
  },
  {
    title: "Industrial Visit at Strats 360 Techno Labs LLP",
    description:
      "",
    images: ["/assets/iil/a4i1.png","/assets/iil/a4i2.png","/assets/iil/a4i3.png","/assets/iil/a4i4.png"],
    report: "/assets/iil/iil4.pdf",
  },
  {
    title: "Industry Academia Meet",
    description:
      "",
    images: ["/assets/iil/a6i1.jpg","/assets/iil/a6i2.jpg","/assets/iil/a6i3.jpg"],
    report: "/assets/iil/iil6.pdf",
  },
  {
    title: "National Foundry Day Celebration",
    description:
      "",
    images: ["/assets/iil/a7i1.png","/assets/iil/a7i2.png","/assets/iil/a7i3.png","/assets/iil/a7i4.png"],
    report: "/assets/iil/iil7.pdf",
  },
  {
    title: "Annual Industry Institute Meet (AIIM-2021)",
    description:
      "",
    images: ["/assets/iil/a8i1.jpg","/assets/iil/a8i2.jpg","/assets/iil/a8i3.jpg"],
    report: "/assets/iil/iil8.pdf",
  },
  {
    title: "Expert Lecture By Mr. Yogeshbhai Kanakiya",
    description:
      "",
    images: ["/assets/iil/a9i1.jpg","/assets/iil/a9i2.jpg","/assets/iil/a9i3.jpg","/assets/iil/a9i4.jpg","/assets/iil/a9i5.jpg"],
    report: "/assets/iil/iil9.pdf",
  },
  {
    title: "Industry Visit at Microsign Products by Mechanical Engineering Department",
    description:
      "",
    images: ["/assets/iil/a10i1.jpg","/assets/iil/a10i2.jpg","/assets/iil/a10i3.jpg"],
    report: "/assets/iil/iil10.pdf",
  },
  {
    title: "Industry Visit at Trushape Precision Castings Private Limited by Mechanical Engineering Department",
    description:
      "",
    images: ["/assets/iil/a11i1.png","/assets/iil/a11i2.jpg"],
    report: "/assets/iil/iil11.pdf",
  },
];

const IILPage = () => {
  return (
    <Layout
      title="Industry Institute Linkage"
      breadcrumb={[
        { name: "Home", path: "/" },
        { name: "Industry Institute Linkage" },
      ]}
    >
      <div className="coe-page">
        {/* Static Description */}
        <div className="coe-page-data">
          <div className="coe-about">
            <h3 className="coe-info-title">About Industry Institute linkage Cell</h3>
            <p className="coe-desc">
              Industry Institute linkage Cell (IIL Cell) at Government Engineering College, Bhavnagar is established to provide closer links with industries. The main objective of the Cell is to promote closer interaction between the academic and Industrial fields. The purpose of the cell is to find out the gap between need of the industry and end product of the institute. The cell is the bridge between the industry, the real world and the institute.
            </p>
            <h3 className="coe-info-title">Objectives</h3>
            <p className="coe-desc">
    <ul>
      <li>Establish linkage between industry and the institute.</li>
      <li>Identify industry problems and provide solutions.</li>
      <li>Joint research projects.</li>
      <li>Dissemination of knowledge.</li>
      <li>Continuing education programs.</li>
      <li>Greater utilization of resources.</li>
      <li>Promoting Internship for students.</li>
      <li>Promoting Placement for students.</li>
      <li>Faculty training in industry.</li>
    </ul>
    </p>
    <h3 className="coe-info-title">Activities</h3>
    <p className="coe-desc">
    <ul>
      <li>Arranging industrial visits/ Training to students.</li>
      <li>Exchange of personnel.</li>
      <li>Organizing industrial exhibitions.</li>
      <li>Conducting seminars, workshops, continuing education programs etc.</li>
      <li>Technology appreciation programs.</li>
    </ul>
    </p>
    
    <h3 className="coe-info-title">Confederation of Indian Industry (CII)</h3>
    <p className="coe-desc">
      Confederation of Indian Industry (CII) is a non-government, not-for-profit, industry-led and industry-managed organization, playing a proactive role in India's development process.
      CII charts change by working closely with Government on policy issues, interfacing with thought leaders, and enhancing efficiency, competitiveness and business opportunities for industry through a range of specialized services and strategic global linkages. 
      It also provides a platform for consensus-building and networking on key issues. <br/>
      Government Engineering College-Bhavnagar is a Member of Confederation of Indian Industry.
    </p>
    
    <h3 className="coe-info-title">VISION</h3>
    <p className="coe-desc">To be a catalyst towards building India as a Developed Nation</p>

    <h3 className="coe-info-title">MISSION</h3>
    <p className="coe-desc">
    <ol>
      <li>One-stop platform for services to industry &amp; society.</li>
      <li>Customer-focused organization.</li>
      <li>World-class services.</li>
      <li>Global thinking and local action.</li>
      <li>Learning and caring organization.</li>
      <li>Partnership approach.</li>
    </ol>
    </p>
    <h3 className="coe-info-title">CII Initiatives</h3>
    <p className="coe-desc">
      CII has embarked on smarter initiatives that enhance competitiveness of Indian industry by underlining the need for rapid upgradation on parameters like quality, corporate governance, knowledge management, energy efficiency and environment management. 
      All this, not only to increase productivity and profitability but also to enhance the quality of life of the community. Different divisions and councils of CII develop and undertake new services for the business community and beyond.
    </p>
    <h3 className="coe-info-title">CII Roles</h3>
    <p className="coe-desc">
      CII's primary goal is to develop Indian industry and to ensure that government and society as a whole, understand both the needs of industry and its contribution to the nation's wellbeing. For this, we work:
    <ul>
      <li>To identify and strengthen industry's role in the economic development of the country.</li>
      <li>To act as a catalyst in bringing about the growth and development of Indian Industry.</li>
      <li>To reinforce industry's commitment to society.</li>
      <li>To provide up-to-date information and data to industry and government.</li>
      <li>To create awareness and support industry's efforts on quality, environment, energy management, and consumer protection.</li>
      <li>To identify and address the special needs of the small sector to make it more competitive.</li>
      <li>To promote cooperation with counterpart organisations.</li>
      <li>To work towards the globalisation of Indian industry and integration into the world economy.</li>
    </ul>
      This is done by adopting a proactive and partnership approach with the government on various national and international issues concerning the Indian economy. It closely interacts on policy issues at both the central and state levels. Extensive dialogue and interaction with members and all sections of the community to build consensus are held.
    </p>
    
    <h3 className="coe-info-title">Formation of Industry Institute Linkage Cell</h3>
<p className="coe-desc">
<table border="1" cellpadding="5" cellspacing="0">
  <thead>
    <tr>
      <th>Sr. No.</th>
      <th>Name</th>
      <th>Designation</th>
      <th>Department</th>
      <th>Role</th>
      <th>Email ID</th>
      <th>Contact Number</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1</td>
      <td>Dr. A. Y. Pathak</td>
      <td>Assistant Professor</td>
      <td>Mechanical Engineering</td>
      <td>Convener</td>
      <td>aypathak.mech@gecbhavnagar.ac.in</td>
      <td>9979537248</td>
    </tr>
    <tr>
      <td>2</td>
      <td>K. R. Rathod</td>
      <td>Assistant Professor</td>
      <td>Computer Engineering</td>
      <td>Member</td>
      <td>krrathod.ce@gecbhavnagar.ac.in</td>
      <td>9033300546</td>
    </tr>
    <tr>
      <td>3</td>
      <td>R. J. Mehta</td>
      <td>Assistant Professor</td>
      <td>Mechanical Engineering</td>
      <td>Member</td>
      <td>rjmehta.mech@gecbhavnagar.ac.in</td>
      <td>9925509600</td>
    </tr>
  </tbody>
</table>
</p>

          </div>
        </div>
        {/* Activity Cards Displaying Newsletter Items */}
        <div className="coe-labs" id="newsletters">
          <h3 className="coe-info-title">Activities</h3>
          <div className="coe-labs-list">
            <ActivityCard activities={iilData} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IILPage;
