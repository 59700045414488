import React from "react";
import Layout from "components/layout/layout";
import "./all_staff.css";

const staffData = [
    {
        id: 3,
        name: "Mr. Vijendrasinh Mahendrasinh Jadeja",
        designation: "Head Clerk",
        department: "Admin Department",
        image: "/assets/adminstaff/vmjadeja.jpeg",
    },
    {
        id: 1,
        name: "Mr. Vishal Pandya",
        designation: "Senior Clerk",
        department: "Admin Department",
        image: "/assets/adminstaff/Vishal Pandya.jpg",
    },
    {
        id: 2,
        name: "Mrs. Zarna Trivedi",
        designation: "Senior Clerk",
        department: "Admin Department",
        image: "/assets/adminstaff/Zarana Trivedi.jpg",
    },
    {
        id: 4,
        name: "Mr. Darshan Kanada",
        designation: "Senior Clerk",
        department: "Admin Department",
        image: "/assets/adminstaff/Darshan Kanada.jpeg",
    },
    {
        id: 5,
        name: "Mr. M. M. Punani",
        designation: "Junioor Clerk",
        department: "Admin Department",
        image: "/assets/adminstaff/mmpunani.jpeg",
    }
];

const AllStaffPage = () => {
    return (
        <Layout
            title="Administrative Staff"
            breadcrumb={[{ name: "Home", path: "/" }, { name: "Staff" }]}
        >
            <div className="all-faculties-page">
                <div className="faculties row r-c-x">
                    {staffData.map((staff) => (
                        <StaffCard key={staff.id} staff={staff} />
                    ))}
                </div>
            </div>
        </Layout>
    );
};

const StaffCard = ({ staff }) => {
    return (
        <div className="faculty">
            <div className="image">
                <img src={staff.image} alt={staff.name} loading="lazy" />
            </div>
            <div className="data column c-c-y">
                <p className="name">{staff.name}</p>
                <div className="designation">
                    <strong>{staff.designation}</strong>
                </div>
                <div className="branch">
                    <strong>{staff.department}</strong>
                </div>
            </div>
        </div>
    );
};

export default AllStaffPage;
