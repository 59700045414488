// newsletter.js
import Layout from "components/layout/layout";
import ActivityCard from "components/activity_card/ActivityCard";
import "./rti.css";

const newsletterData = [
  
];

const RTIPage = () => {
  return (
    <Layout
      title="RTI"
      breadcrumb={[
        { name: "Home", path: "/" },
        { name: "RTI" },
      ]}
    >
      <div className="coe-page">
        {/* Static Description */}
        <div className="coe-page-data">
          <div className="coe-about">
            
            <h3 className="coe-info-title">Formation</h3>
            <img src="/assets/rti/rti.jpeg" alt="" style={{ maxWidth: "100%" }} />
            <br/>
            <p className="coe-desc">

<table border="1" cellpadding="5" cellspacing="0">
    <tr>
        <th>Sr. No.</th>
        <th>Name</th>
        <th>Designation</th>
        <th>Department</th>
        <th>Role</th>
        <th>Email ID</th>
        <th>Contact Number</th>
    </tr>
    <tr>
        <td>1</td>
        <td>Prof. (Dr.) M. G. Bhatt</td>
        <td>Principal</td>
        <td>-</td>
        <td>Appellate Officer (AO- RTI)</td>
        <td>principal@gecbhavnagar.ac.in</td>
        <td>98982 38687</td>
    </tr>
    <tr>
        <td>2</td>
        <td>Prof. (Dr.) J. B. Valaki</td>
        <td>Associate Professor</td>
        <td>Mechanical Engineering</td>
        <td>Public Information Officer (PIO)</td>
        <td>jbvalaki.mech@gecbhavnagar.ac.in</td>
        <td>9913715250</td>
    </tr>
    <tr>
        <td>3</td>
        <td>Prof. D. B. Morabiya</td>
        <td>Assistant Professor</td>
        <td>Mechanical Engineering</td>
        <td>Assistant Public Information Officer (APIO)</td>
        <td>dbmorabiya.mech@gecbhavnagar.ac.in</td>
        <td>9925486045</td>
    </tr>
</table>


</p>
         </div>
        </div>
        
      </div>
    </Layout>
  );
};

export default RTIPage;
