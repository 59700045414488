// newsletter.js
import Layout from "components/layout/layout";
import ActivityCard from "components/activity_card/ActivityCard";
import "./ahicc.css";

const newsletterData = [
  {
    title: "Awareness Seminar (01/02/2025)",
    description:
      "",
    images: ["/assets/ahicc/a5i1.jpeg","/assets/ahicc/a5i2.jpeg","/assets/ahicc/a5i3.jpeg"],
    report: "/assets/ahicc/ahicc5.pdf",
  },
  {
    title: "Awareness Seminar (05/12/2024)",
    description:
      "",
    images: ["/assets/ahicc/a4i1.jpeg","/assets/ahicc/a4i2.jpeg","/assets/ahicc/a4i3.jpeg"],
    report: "/assets/ahicc/ahicc4.pdf",
  },
  {
    title: "Expert Talk on How to be Cyber Safe in Modern Era  (05/08/2024)",
    description:
      "",
    images: ["/assets/ahicc/a3i1.jpeg","/assets/ahicc/a3i2.jpeg","/assets/ahicc/a3i3.png","/assets/ahicc/a3i4.png"],
    report: "/assets/ahicc/ahicc3.pdf",
  },
  {
    title: "Awareness Seminar (18/04/2024)",
    description:
      "",
    images: ["/assets/ahicc/a2i1.jpeg","/assets/ahicc/a2i2.jpeg","/assets/ahicc/a2i3.jpeg"],
    report: "/assets/ahicc/ahicc2.pdf",
  },
  {
    title: "Awareness Seminar by Abhayam Team (23/02/2023)",
    description:
      "",
    images: ["/assets/ahicc/a1i1.jpg","/assets/ahicc/a1i2.jpg","/assets/ahicc/a1i3.jpg"],
    report: "/assets/ahicc/ahicc1.pdf",
  },
];

const AHICC = () => {
  return (
    <Layout
      title="Anti-Harassment and Internal Complaints Committee (ICC) and Girls Counselling Cell"
      breadcrumb={[
        { name: "Home", path: "/" },
        { name: "Anti-Harassment and Internal Complaints Committee (ICC) and Girls Counselling Cell" },
      ]}
    >
      <div className="coe-page">
        {/* Static Description */}
        <div className="coe-page-data">
          <div className="coe-about">
            <h3 className="coe-info-title">Anti-Harassment and Internal Complaint Committee (ICC) and Girls Counselling Cell</h3>
            <p className="coe-desc">
              These mechanisms are established to address and prevent workplace harassment for women, particularly sexual harassment. This cell works specifically for the girl students studying and women working in the campus of Government Engineering College, Bhavnagar. These systems derive their mandate from the <strong>Sexual Harassment of Women at Workplace (Prevention, Prohibition, and Redressal) Act, 2013</strong>, often referred to as the POSH Act, 2013.
            </p>
            
            <h3 className="coe-info-title">Purpose</h3>
            <p className="coe-desc">
              An Anti-Harassment Cell may be established by organizations or institutions (such as educational institutions, corporate offices, or public bodies) to address broader harassment issues, including but not limited to sexual harassment.
            </p>
            
            <h3 className="coe-info-title">Formation</h3>
            <p className="coe-desc">
<table border="1" cellpadding="5" cellspacing="0">
    <tr>
        <th>Sr. No.</th>
        <th>Name</th>
        <th>Designation</th>
        <th>Department</th>
        <th>Role</th>
        <th>Email ID</th>
        <th>Contact Number</th>
    </tr>
    <tr>
        <td>1</td>
        <td>Dr. A. N. Prajapati</td>
        <td>Associate Professor</td>
        <td>Mechanical Engineering</td>
        <td>Convener</td>
        <td>anprajapati.mech@gecbhavnagar.ac.in</td>
        <td>9427806369</td>
    </tr>
    <tr>
        <td>2</td>
        <td>V. S. Dave</td>
        <td>Associate Professor</td>
        <td>Civil Engineering</td>
        <td>Faculty Member</td>
        <td>vsdave.civil@gecbhavnagar.ac.in</td>
        <td>9998942010</td>
    </tr>
    <tr>
        <td>3</td>
        <td>B. K. Jambucha</td>
        <td>Assistant Professor</td>
        <td>Electronics and Communication Department</td>
        <td>Faculty Member</td>
        <td>bkjambucha.ec@gecbhavnagar.ac.in</td>
        <td>9662003824</td>
    </tr>
    <tr>
        <td>4</td>
        <td>K. M. Vagadiya</td>
        <td>Assistant Professor</td>
        <td>Electronics and Communication Department</td>
        <td>Faculty Member</td>
        <td>kmvagadiya.ec@gecbhavnagar.ac.in</td>
        <td>7567112287</td>
    </tr>
    <tr>
        <td>5</td>
        <td>Dr. Alpaben G Chavda, M.S. Gynecologist, Madhudeep Hospital, IVF center, Bhavnagar</td>
        <td>Doctor</td>
        <td>Not applicable</td>
        <td>External Member working for Women Health and Other Issues</td>
        <td>dralpa.chavda@gmail.com</td>
        <td>-</td>
    </tr>
    <tr>
        <td>6</td>
        <td>Mehta Vanshika M.</td>
        <td>Student</td>
        <td>ICT Engg.</td>
        <td>Student Member</td>
        <td>-</td>
        <td>-</td>
    </tr>
    <tr>
        <td>7</td>
        <td>Tanna Sanjana C.</td>
        <td>Student</td>
        <td>IT Engg.</td>
        <td>Student Member</td>
        <td>-</td>
        <td>-</td>
    </tr>
</table>
  

            </p>
            
            <h3 className="coe-info-title">Functioning</h3>
            <p className="coe-desc">
              <ul>
        <li><strong>Address Complaints:</strong> Receive and investigate complaints of sexual harassment.</li>
        <li><strong>Conduct Inquiry:</strong> Carry out a fair and confidential inquiry, following the duly recommended process.</li>
        <li><strong>Counselling:</strong> Provide counselling for victims and accused from different perspectives and attempt resolution at the local level.</li>
        <li><strong>Recommend Actions:</strong> Suggest suitable actions, such as disciplinary measures or legal actions, based on findings.</li>
        <li><strong>Maintain Confidentiality:</strong> The Cell ensures the utmost protection of the complainant�s and accused�s identity.</li>
        <li><strong>Legal Action Support:</strong> For unresolved complaints, the ICC committee provides administrative support to women or girl students to lodge complaints at police stations, state women cells, or cyber cells based on the category of complaints.</li>
        <li><strong>Preventive Measures:</strong> Educate and create awareness about sexual harassment laws and rights. The cell also conducts seminars with external experts on women's safety, legal awareness, and cyber safety.</li>
    </ul>
            </p>
            <h3 className="coe-info-title">Activities</h3>
            <p className="coe-desc">
              <ul>
        <li>Awareness Seminars about the <strong>POSH Act, 2013</strong> and women's rights.</li>
        <li>Conducted training sessions for women employees and girl students to spread awareness about helplines, government cells, and women support services such as Abhayam and 24x7 helpline numbers.</li>
        <li>Supported to victims in registering complaints and provided emotional support.</li>
        <li>Conducted various awareness sessions on legal rights of women, health issues, cyber security awareness, and government helpline numbers.</li>
    </ul>
            </p>
          </div>
        </div>
        {/* Activity Cards Displaying Newsletter Items */}
        <div className="coe-labs" id="newsletters">
         
          <div className="coe-labs-list">
            <ActivityCard activities={newsletterData} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AHICC;
