import Layout from "components/layout/layout";
import DataProvider from "hoc/data_provider";
import ClubCard from "./clubCard";
import "./clubs.css";
import ActivityCard from "../../components//activity_card/ActivityCard";

const Clubs = () => {
    return <DataProvider endPoint="/web/clubs" component={ClubComponent} />;
};

export default Clubs;

const ClubComponent = ({ data }) => {
const activityData = [
        {
            title: "Activities Done During Academic Year 2024-25",
            description: "Click to view list of all exiting activities done by various clubs of GEC Bhavnagar during the academic year 2024-25.",
            images: [
                "assets/clubs/images/2024-25/1.jpg",
                "assets/clubs/images/2024-25/2.jpg",
                "assets/clubs/images/2024-25/3.jpg",
                "assets/clubs/images/2024-25/4.jpg",
                "assets/clubs/images/2022-23/1.png",
                "assets/clubs/images/2022-23/2.png",
                "assets/clubs/images/2022-23/3.png",
                "assets/clubs/images/2022-23/4.png",
                "assets/clubs/images/2022-23/5.png",
                "assets/clubs/images/2022-23/6.png",
                "assets/clubs/images/2022-23/7.png",
                "assets/clubs/images/2022-23/8.png",
                "assets/clubs/images/2022-23/9.png",
                "assets/clubs/images/2022-23/12.png",
                "assets/clubs/images/2022-23/11.png",
                "assets/clubs/images/2022-23/12.png",
                "assets/clubs/images/2022-23/13.png",
                "assets/clubs/images/2022-23/14.png"
            ],
            report: "assets/clubs/Combined 2024-25.pdf"
        },
        {
            title: "Activities Done During Academic Year 2023-24",
            description: "Click to view list of all exiting activities done by various clubs of GEC Bhavnagar during the academic year 2023-24.",
            images: [
                "assets/clubs/images/2023-24/1.png",
                "assets/clubs/images/2023-24/2.png",
                "assets/clubs/images/2023-24/3.png",
                "assets/clubs/images/2023-24/4.png",
                "assets/clubs/images/2023-24/5.png",
                "assets/clubs/images/2023-24/6.png",
                "assets/clubs/images/2023-24/7.png",
                "assets/clubs/images/2023-24/8.png",
                "assets/clubs/images/2023-24/9.png",
                "assets/clubs/images/2023-24/10.png"
            ],
            report: "assets/clubs/Combined 2023-24.pdf"
        },
        {
            title: "Activities Done During Academic Year 2022-23",
            description: "Click to view list of all exiting activities done by various clubs of GEC Bhavnagar during the academic year 2022-23.",
            images: [
                "assets/clubs/images/2022-23/1.png",
                "assets/clubs/images/2022-23/2.png",
                "assets/clubs/images/2022-23/3.png",
                "assets/clubs/images/2022-23/4.png",
                "assets/clubs/images/2022-23/5.png",
                "assets/clubs/images/2022-23/6.png",
                "assets/clubs/images/2022-23/7.png",
                "assets/clubs/images/2022-23/8.png",
                "assets/clubs/images/2022-23/9.png",
                "assets/clubs/images/2022-23/12.png",
                "assets/clubs/images/2022-23/11.png",
                "assets/clubs/images/2022-23/12.png",
                "assets/clubs/images/2022-23/13.png",
                "assets/clubs/images/2022-23/14.png"
            ],
            report: "assets/clubs/Combined 2022-23.pdf"
        },

    ];

    return (
        <div className="clubs">
            <Layout
                title="Clubs"
                breadcrumb={[
                    { name: "Home", path: "/" },
                    {
                        name: "Clubs",
                    },
                ]}
            >
                <div className="wrapper">
                    <div className="clubs_contain">
                        {data.map((club) => {
                            return <ClubCard key={club._id} club={club} />;
                        })}
                        <ActivityCard activities={activityData} />
                    </div>
                </div>
            </Layout>
        </div>
    );
};
