import React from "react";
import "./ImageSlider.css";
import { Carousel } from "react-responsive-carousel";

const ImageSlider = ({ images }) => {
    return (
        <Carousel
            autoPlay
            infiniteLoop
            showThumbs={false}
            showIndicators={false}
        >
            {images.map((image, idx) => (
                <div className="slider_image" key={idx}>
                    <img src={image} alt={`img-${idx}`} />
                </div>
            ))}
        </Carousel>
    );
};

export default ImageSlider;

