// newsletter.js
import Layout from "components/layout/layout";
import ActivityCard from "components/activity_card/ActivityCard";
import "./arc.css";

const newsletterData = [
  
];

const ARCPage = () => {
  return (
    <Layout
      title="Anti-Ragging Cell"
      breadcrumb={[
        { name: "Home", path: "/" },
        { name: "Anti-Ragging Cell" },
      ]}
    >
      <div className="coe-page">
        {/* Static Description */}
        <div className="coe-page-data">
          <div className="coe-about">
            <h3 className="coe-info-title">Formation</h3>
            <p className="coe-desc">
              <table border="1" cellpadding="5" cellspacing="0">
  <thead>
    <tr>
      <th>Sr. No.</th>
      <th>Name</th>
      <th>Designation</th>
      <th>Department</th>
      <th>Role</th>
      <th>Email ID</th>
      <th>Contact Number</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1</td>
      <td>A. G. Kunte</td>
      <td>Associate Professor</td>
      <td>Production Engineering</td>
      <td>Convener</td>
      <td>agkunte.prod@gecbhavnagar.ac.in</td>
      <td>9426128779</td>
    </tr>
    <tr>
      <td>2</td>
      <td>Dr. A. N. Prajapati</td>
      <td>Associate Professor</td>
      <td>Mechanical Engineering</td>
      <td>Member</td>
      <td>anprajapati.mech@gecbhavnagar.ac.in</td>
      <td>9427806369</td>
    </tr>
    <tr>
      <td>3</td>
      <td>V. S. Dave</td>
      <td>Associate Professor</td>
      <td>Civil Engineering</td>
      <td>Member</td>
      <td>vsdave.civil@gecbhavnagar.ac.in</td>
      <td>9998942010</td>
    </tr>
    <tr>
      <td>4</td>
      <td>Dr. A. C. Rathod</td>
      <td>Assistant Professor</td>
      <td>Electronics &amp; Communication Engineering</td>
      <td>Member</td>
      <td>acrathod.ec@gecbhavnagar.ac.in</td>
      <td>9662511781</td>
    </tr>
    <tr>
      <td>5</td>
      <td>A. P. Patel</td>
      <td>Assistant Professor</td>
      <td>IT Engineering</td>
      <td>Member</td>
      <td>appatel.it@gecbhavnagar.ac.in</td>
      <td>8866746787</td>
    </tr>
    <tr>
      <td>6</td>
      <td>K. P. Kandoriya</td>
      <td>Assistant Professor</td>
      <td>Computer Engineering</td>
      <td>Member</td>
      <td>kpkandoriya.ce@gecbhavnagar.ac.in</td>
      <td>9033711408</td>
    </tr>
  </tbody>
</table>

<h3 className="coe-info-title">Punishments for those found guilty of ragging at the institution level</h3>
<p className="coe-desc">
Depending upon the nature and gravity of the offence as established the possible punishments for those found guilty of ragging at the institution level shall be any one or in combination of the following:-
  <ul>
    <li>Cancellation of admission</li>
    <li>Suspension from attending classes</li>
    <li>Withholding/withdrawing scholarship/fellowship and other benefits</li>
    <li>Withholding results</li>
    <li>Debarring from appearing in any test, examination or other evaluation process/representing the institution in any regional, national or international meet, tournament, youth festival, etc.</li>
    <li>Suspension/expulsion from the hostel</li>
    <li>Rustication from the institution</li>
    <li>Expulsion from the institution and consequent debarring from admission to any other institution.</li>
  </ul>
            </p>
            
            <h3 className="coe-info-title">Instructions to Parents</h3>
            <p className="coe-desc">
  <ul>
    <li>Instruct your wards to desist from ragging freshers or other students in any form.</li>
    <li>Keep regular contact with your wards and monitor their well being and academic progress.</li>
    <li>Frequently interact with the Class Coordinator and other faculty members to track the welfare and progress of your wards.</li>
  </ul>
</p>
  <h3 className="coe-info-title">Important Information</h3>
  <p className="coe-desc">
  <ol>
    <li><a href="https://gecbhavnagar.ac.in:8000/api/v1/download/files/67aedefee21c2f381179f445" target="_blank">Directives of the Honorable Supreme Court on the Menace of Ragging</a></li>
    <li><a href="https://gecbhavnagar.ac.in:8000/api/v1/download/files/67aee033e21c2f381179f45f" target="_blank">UGC Regulations on curbing the menace of Ragging</a></li>
    <li><a href="https://gecbhavnagar.ac.in:8000/api/v1/download/files/67aee053e21c2f381179f46a" target="_blank">Affidavit by the Student &amp; Parent/Guardian</a></li>
    <li><a href="https://gecbhavnagar.ac.in:8000/api/v1/download/files/67aedf85e21c2f381179f44a" target="_blank">AICTE Appendix 12: Prevention &amp; Prohibition of Ragging</a></li>
    
  </ol>
  </p>
 

            </p>
          </div>
        </div>
        
      </div>
    </Layout>
  );
};

export default ARCPage;
