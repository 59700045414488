// newsletter.js
import Layout from "components/layout/layout";
import ActivityCard from "components/activity_card/ActivityCard";
import "./nss.css";

const nssData = [
  {
    title: "NSS Annual Report 2024-25",
    description: "",
    images: ["/assets/nss/a1i1.jpg","/assets/nss/a1i2.jpeg","/assets/nss/a1i3.jpeg","/assets/nss/a1i4.jpeg","/assets/nss/a1i5.jpeg","/assets/nss/a1i6.jpeg","/assets/nss/a1i7.jpeg","/assets/nss/a1i8.jpeg","/assets/nss/a1i9.jpeg","/assets/nss/a1i10.jpeg","/assets/nss/a1i11.jpeg","/assets/nss/a1i12.jpeg"],
    report: "/assets/nss/nss202425.pdf",
  },
  {
    title: "NSS Annual Report 2023-24",
    description: "",
    images: ["/assets/nss/a2i1.png","/assets/nss/a2i2.png","/assets/nss/a2i3.png","/assets/nss/a2i4.png","/assets/nss/a2i5.png"],
    report: "/assets/nss/nss202324.pdf",
  },
  {
    title: "NSS Annual Report 2022-23",
    description: "",
    images: ["/assets/nss/a3i1.png","/assets/nss/a3i2.png","/assets/nss/a3i3.png","/assets/nss/a3i4.png","/assets/nss/a3i5.png","/assets/nss/a3i6.png","/assets/nss/a3i7.png","/assets/nss/a3i8.png","/assets/nss/a3i9.png"],
    report: "/assets/nss/nss202223.pdf",
  },
  
];

const NSSPage = () => {
  return (
    <Layout
      title="NSS"
      breadcrumb={[
        { name: "Home", path: "/" },
        { name: "NSS" },
      ]}
    >
      <div className="coe-page">
        {/* Static Description */}
        <div className="coe-page-data">
          <div className="coe-about">
            <h3 className="coe-info-title">About NSS</h3>
            <p className="coe-desc">
             &emsp; The National Service Scheme (NSS) is a public service initiative under the Ministry of Youth Affairs and Sports, Government of India, aimed at developing students' sense of social responsibility through community service. It encourages young volunteers to participate in activities that promote education, health awareness, environmental protection, and social welfare.

&emsp;The logo is inspired by the giant wheel of the Konark Sun Temple in Odisha, symbolizing movement, progress, and the cycle of life. The eight bars of the wheel represent the 24 hours of a day, signifying that NSS volunteers must remain active and committed to selfless service at all times. The red color in the logo represents energy and enthusiasm, while the blue color symbolizes the vastness of the universe and the NSS�s commitment to national and social development.

The caption of NSS, "Not Me, But You," reflects its core philosophy of selflessness and service. It emphasizes the importance of placing community welfare above personal interests, promoting social responsibility, and encouraging students to work towards societal development. Through various activities, NSS instills values of teamwork, empathy, and leadership in its volunteers, shaping them into responsible citizens.

At Government Engineering College, Bhavnagar, one NSS unit comprising 100 students has been granted by Gujarat Technological University (GTU) since 2014. From 2021 onwards, Dr. Himanshu A. Srivastava has been serving as the Programme Officer, while Mr. Darshan A. Bhatt is the Programme Coordinator. The other coordinators include Asst. Prof. Urvashi L. Solanki and Asst. Prof. Bindi Joshi, who actively contribute to organizing various outreach and development programs, fostering a spirit of volunteerism and leadership among students.
            </p>
          </div>
        </div>
        {/* Activity Cards Displaying Newsletter Items */}
        <div className="coe-labs" id="newsletters">
          <h3 className="coe-info-title">Activities</h3>
          <div className="coe-labs-list">
            <ActivityCard activities={nssData} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NSSPage;
