import Layout from "components/layout/layout";
import { marked } from "helpers/helpers";
import React from "react";
import "./info_page.css";
import axios from "axios";

const InfoPage = ({ data, id, innerID }) => {
    const URL = `${axios.defaults.baseURL}/download/files/`;

    let showData = [];
    if (innerID === "all") {
        switch (id) {
            case "home":
                showData = data;
                break;
            case "admission":
                showData = data.filter((item) => 
                    item.type === "fya" || item.type === "d2da" || item.type === "ac"
                );
                break;
            case "scholarships":
                showData = data.filter((item) => 
                    item.type === "dg" || item.type === "mysy" || item.type === "nsp"
                );
                break;
            case "gtu-related":
                showData = data.filter((item) => 
                    item.type === "nc" || item.type === "ct" || item.type === "r&r"
                );
                break;
            case "forms-formats":
                showData = data.filter((item) => item.type === "f&f");
                break;
            case "fees":
                showData = data.filter((item) => item.type === "fees");
                break;
            default:
                break;
        }
    } else {
        switch (innerID) {
            case "first-year-admissions":
                showData = data.filter((item) => item.type === "fya");
                break;
            case "d2d-admissions":
                showData = data.filter((item) => item.type === "d2da");
                break;
            case "admission-cancellation":
                showData = data.filter((item) => item.type === "ac");
                break;
            case "digital-gujarat":
                showData = data.filter((item) => item.type === "dg");
                break;
            case "mysy":
                showData = data.filter((item) => item.type === "mysy");
                break;
            case "nsp":
                showData = data.filter((item) => item.type === "nsp");
                break;
            case "name-correction":
                showData = data.filter((item) => item.type === "nc");
                break;
            case "college-transfer":
                showData = data.filter((item) => item.type === "ct");
                break;
            case "recheck-&-reassessment":
                showData = data.filter((item) => item.type === "r&r");
                break;
            default:
                break;
        }
    }

    // Sort data by updatedAt in descending order (newest first)
    showData.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

    return (
        <Layout
            title={
                innerID !== "all"
                    ? innerID.replaceAll("-", " ").toUpperCase()
                    : id.toUpperCase()
            }
        >
            <main className="info-page">
                <div className="contents">
                    <ul className="entry-list">
                        {showData.map((item, idx) => {
                            return (
                                <li key={idx.toString()}>
                                    <div className="info_page_card">
                                        <p
                                            dangerouslySetInnerHTML={marked(
                                                item.description
                                            )}
                                        ></p>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </main>
        </Layout>
    );
};

export default InfoPage;
