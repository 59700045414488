import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./ActivityCard.css";  // Import the CSS file

const ActivityCard = ({ activities }) => {
    return (
        <div className="activity-container">
            {activities.map((activity, index) => {
                const { title, description, images, report } = activity;
                
                return (
                    <a 
                        key={index} 
                        href={report} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="activity-card"
                    >
                        <div className="card-content">
                            <Carousel
                                className="activity-gallery"
                                autoPlay={true}
                                infiniteLoop={true}
                                showThumbs={false}
                                showIndicators={false}
                            >
                                {images.map((image, idx) => (
                                    <div key={idx} className="activity-image">
                                        <img src={image} alt="activity-img" />
                                    </div>
                                ))}
                            </Carousel>
                            <div className="activity-info">
                                <h5 className="activity-title">{title}</h5>
                                <p className="activity-desc">{description}</p>
                            </div>
                        </div>
                    </a>
                );
            })}
        </div>
    );
};

export default ActivityCard;
