import React from 'react';
import Layout from 'components/layout/layout';
import './admissionprocess.css'; // Make sure this file exists

const AdmissionProcess = () => {
  return (
    <div className="admission-process">
      <Layout title="Admission Process" />
      <h1 className="admissionProcessTitle">Admission Process</h1>
      <p className="admissionProcessContent">
        100% seats are filled by Admission Committee for professional courses (Ahmedabad). Please refer to the websites below for more details.
      </p>
      <br/>
      <ul className="admissionLinks">
        <li>
          <a href="https://gujacpc.admissions.nic.in/" target="_blank" rel="noopener noreferrer">
            For Undergraduate Admissions: Admission Committee for Professional Courses (ACPC), Gujarat
          </a>
        </li>
        <li>
          <a href="https://gujdiploma.admissions.nic.in/" target="_blank" rel="noopener noreferrer">
            For Diploma to Degree Admissions: Admission Committee for Professional Diploma Courses (ACPDC), Gujarat
          </a>
        </li>
        <li>
          <a href="https://acpc.gujarat.gov.in/me-mpharm-courses/" target="_blank" rel="noopener noreferrer">
            For Postgraduate Admissions: Admission Committee for Professional Courses (ACPC), Gujarat
          </a>
        </li>
      </ul>
    </div>
  );
};

export default AdmissionProcess;

