import React from 'react';
import Layout from 'components/layout/layout';
import campusMap from 'assets/campusmap_final.png'; // Adjust the path as necessary
import './campusmap.css'; // Create this file for custom styles

const CampusMap = () => {
  return (
    <div className="campus-map">
      <Layout title="Campus Map" />
      <h1 className="campusMapTitle">Campus Map</h1>
      <div className="campusMapImageContainer">
        <img src={campusMap} alt="Campus Map" className="campusMapImage" />
      </div>
    </div>
  );
};

export default CampusMap;

