// newsletter.js
import Layout from "components/layout/layout";
import ActivityCard from "components/activity_card/ActivityCard";
import "./newsletter.css";

const newsletterData = [
  {
    title: "Tecknovation Volume-V Issue-II (June-2024 to December-2024)",
    description:
      "Tecknovation Volume-V Issue-II covering the period of June-2024 to December-2024.",
    images: ["/assets/newsletter/images/t9.png"],
    report: "https://drive.google.com/file/d/1kYIMv5LATXhPFeTHxocAmm68zjndzATl/view?usp=drive_link",
  },
  {
    title: "Tecknovation Volume-V Issue-I (January-2024 to June-2024)",
    description:
      "Tecknovation Volume-V Issue-I covering the period of January-2024 to June-2024.",
    images: ["/assets/newsletter/images/t8.png"],
    report: "https://drive.google.com/file/d/1hs_8r6XB59F2SN5PG_4c-oi7S7ThocgO/view?usp=sharing",
  },
  {
    title: "Tecknovation Volume-IV Issue-II (July-2023 to December-2023)",
    description:
      "Tecknovation Volume-IV Issue-II covering the period of July-2023 to December-2023.",
    images: ["/assets/newsletter/images/t7.png"],
    report: "https://drive.google.com/file/d/1ds8xcyelHsXfFsEsdX-OMu_xYyxrX0oe/view?usp=drive_link",
  },
  {
    title: "Tecknovation Volume-IV Issue-I (January-2023 to June-2023)",
    description:
      "Tecknovation Volume-IV Issue-I covering the period of January-2023 to June-2023.",
    images: ["/assets/newsletter/images/t6.png"],
    report: "https://drive.google.com/file/d/1tiy_NY3rBaOdLCgUlev9GVXSuDHiicuA/view?usp=sharing",
  },
  {
    title: "Tecknovation Volume-III Issue-II (July-2022 to December-2022)",
    description:
      "Tecknovation Volume-V Issue-I covering the period of July-2022 to December-2022.",
    images: ["/assets/newsletter/images/t5.png"],
    report: "https://drive.google.com/file/d/11VkWDygN76Cgn4hTXg-gw_hm7N8WfiW5/view?usp=sharing",
  },
  {
    title: "Tecknovation Volume-III Issue-I (January-2022 to June-2022)",
    description:
      "Tecknovation Volume-III Issue-I covering the period of January-2022 to June-2022.",
    images: ["/assets/newsletter/images/t4.png"],
    report: "https://drive.google.com/file/d/1iHzmOqnwP8NSWOI7NRcOy1vGv4HZziZh/view?usp=sharing",
  },
  {
    title: "Tecknovation Volume-II Issue-II (July-2021 to December-2021)",
    description:
      "Tecknovation Volume-II Issue-II covering the period of July-2021 to December-2021.",
    images: ["/assets/newsletter/images/t3.png"],
    report: "https://drive.google.com/file/d/1nezDPGo6fRv7WXvKbMpjnHcFatpqkIK9/view?usp=sharing",
  },
  {
    title: "Tecknovation Volume-II Issue-I (January-2021 to June-2021)",
    description:
      "Tecknovation Volume-II Issue-I covering the period of January-2021 to June-2021.",
    images: ["/assets/newsletter/images/t2.png"],
    report: "https://drive.google.com/file/d/1O4ZZjJlT4IgykxTNbEutBmvTkgb753wj/view?usp=sharing",
  },
  {
    title: "Tecknovation Volume-I Issue-I (July-2020 to December-2020)",
    description:
      "Tecknovation Volume-I Issue-I covering the period of July-2020 to December-2020.",
    images: ["/assets/newsletter/images/t1.png"],
    report: "https://drive.google.com/file/d/1BxHo0sPjTnCbQ2jaeBIzwtanV8UwLXmd/view?usp=sharing",
  },
];

const NewsletterPage = () => {
  return (
    <Layout
      title="College Newsletter"
      breadcrumb={[
        { name: "Home", path: "/" },
        { name: "College Newsletter" },
      ]}
    >
      <div className="coe-page">
        {/* Static Description */}
        <div className="coe-page-data">
          <div className="coe-about">
            <h3 className="coe-info-title">About Tecknovtion</h3>
            <p className="coe-desc">
              We are glad to introduce our biannual college newsletter 'Tecknovation' of Government Engineering College, Bhavnagar. As the name given to it, this newsletter displays the spectrum of our engagement and preoccupation with Technology Knowledge and Innovation at our college. The aim of this newsletter is to circulate the information about activities and achievements of our college and keep the readers updated about its accomplishments. This introductory issue provides us the glimpse to our academic, research and co-curricular activities. It shows the infrastructure, facilities and courses available at the college and also show the academic and research contribution of the faculties. Our students, academic, research and co-curricular achievements indicate the success of the teaching-learning process and students' motivation. Several activities are also co-ordinated and managed by students themselves. Throughout the year our college is throbbing with different academic, technical, research and co- curricular activities and we have tried to accommodate as many as we can considering the space available.
            </p>
          </div>
        </div>
        {/* Activity Cards Displaying Newsletter Items */}
        <div className="coe-labs" id="newsletters">
          <h3 className="coe-info-title">Newsletters</h3>
          <div className="coe-labs-list">
            <ActivityCard activities={newsletterData} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NewsletterPage;
