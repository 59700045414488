// newsletter.js
import Layout from "components/layout/layout";
import ActivityCard from "components/activity_card/ActivityCard";
import "./sec.css";

const secData = [
  {
    title: "Government Engineering College Bhavnagar stood Third in whole Gujarat and First in alll Technical Education for Registering Enrolling students to go for SCOPE Exam",
    description:
      "",
    images: ["/assets/sec/sec.jpeg"],
    report: "/assets/sec/sec.pdf",
  },
];

const SECPage = () => {
  return (
    <Layout
      title="Skill Empowerment"
      breadcrumb={[
        { name: "Home", path: "/" },
        { name: "Skill Empowerment" },
      ]}
    >
      <div className="coe-page">
        {/* Static Description */}
        <div className="coe-page-data">
          <div className="coe-about">
            
            <h3 className="coe-info-title">Soft Skills and Technical Skills Development at Government Engineering College, Bhavnagar</h3>
            <p className="coe-desc">
            Government Engineering College, Bhavnagar, is deeply committed to the holistic development of its students, equipping them with both technical expertise and essential soft skills to excel in their professional journeys. To foster this growth, the college has established a prestigious Memorandum of Understanding (MoU) with IIT Bombay for the Spoken Tutorial Course. This initiative offers a comprehensive suite of software-related courses, ranging from fundamental computer skills to advanced CAD/CAM applications, ensuring students gain hands-on experience with industry-relevant tools. <br/><br/>

Additionally, the college has received substantial funding from KCG Finishing School to implement an enriching programme titled "Finishing School." This initiative is designed to enhance functional English proficiency and sharpen employability skills, empowering students with strong communication abilities and workplace readiness.Total 17 Batches have successfully completed training. <br/><br/>

Further strengthening its commitment to language proficiency and digital literacy, Government Engineering College, Bhavnagar, houses a state-of-the-art DELL/SCOPE Lab with 25 high-end PCs provided to the college. The institution has achieved remarkable success in the SCOPE CPT Exam, with over 650 students successfully passing the certification. The college has demonstrated excellence by securing the third position across Gujarat and ranking first among all technical institutions in student enrollment for the SCOPE Exam.<br/><br/>

Dr. Himanshu A. Srivastava was felicitated by KCG SCOPE for his outstanding dedication in mentoring students, teaching them free of cost, and ensuring their thorough preparation for the exam. His efforts have led to the successful execution of the programme, making Government Engineering College, Bhavnagar, a leader in skill development and career-oriented education.
<br/><br/>
With these well-structured programmes, esteemed collaborations, and a strong emphasis on both technical and soft skills, Government Engineering College, Bhavnagar, continues to provide an exceptional learning environment, bridging the gap between academia and industry and preparing students for a successful future.
</p>
         </div>
        </div>
        <div className="coe-labs" id="newsletters">
          <h3 className="coe-info-title">Recognition</h3>
          <div className="coe-labs-list">
            <ActivityCard activities={secData} />
          </div>
        </div> 
      </div>
    </Layout>
  );
};

export default SECPage;
