// newsletter.js
import Layout from "components/layout/layout";
import ActivityCard from "components/activity_card/ActivityCard";
import "./ssip.css";

const ssipData = [
  {
    title: "Research and innovation at Grassroot level and role of Government in promoting innovation",
    description:
      "",
    images: ["/assets/ssip/a1i1.jpeg","/assets/ssip/a1i2.jpeg"],
    report: "/assets/ssip/ssip1.pdf",
  },
  {
    title: "World Entrepreneurship Day Celebration",
    description:
      "",
    images: ["/assets/ssip/a2i1.jpg","/assets/ssip/a2i1.jpg"],
    report: "/assets/ssip/ssip2.pdf",
  },
  {
    title: "Engineers Day Celebration 2023",
    description:
      "",
    images: ["/assets/ssip/a3i1.jpg","/assets/ssip/a3i2.JPG"],
    report: "/assets/ssip/ssip3.pdf",
  },
  {
    title: "KAIZEN-2023",
    description:
      "",
    images: ["/assets/ssip/a4i1.jpg","/assets/ssip/a4i2.jpg","/assets/ssip/a4i3.jpg","/assets/ssip/a4i4.jpg"],
    report: "/assets/ssip/ssip4.pdf",
  },
  {
    title: "Student Startup Innovation and Research Festival",
    description:
      "",
    images: ["/assets/ssip/a5i1.jpg","/assets/ssip/a5i2.jpg"],
    report: "/assets/ssip/ssip5.pdf",
  },
  {
    title: "Workshop on Rapid Prototyping",
    description:
      "",
    images: ["/assets/ssip/a6i1.jpg","/assets/ssip/a6i2.jpg"],
    report: "/assets/ssip/ssip6.pdf",
  },
  {
    title: "Proof of Concept (PoC) Exhibition",
    description:
      "",
    images: ["/assets/ssip/a7i1.JPG","/assets/ssip/a7i2.jpeg"],
    report: "/assets/ssip/ssip7.pdf",
  },
  {
    title: "SSIP Awareness Worksho2021",
    description:
      "",
    images: ["/assets/ssip/a8i1.png"],
    report: "/assets/ssip/ssip8.pdf",
  },
];

const ssipData2 = [
  {
    title: "SSIP 2.0 OFFICE ORDER 2024",
    description:
      "",
    images: ["/assets/ssip/ssip2.png"],
    report: "/assets/ssip/SSIP 2.0 OFFICE ORDER 2024.pdf",
  },
];


const ssipData3 = [
  {
    title: "SSIP Coworking Space at GEC, Bhavnagar",
    description:
      "",
    images: ["/assets/ssip/ssip3.png"],
    report: "/assets/ssip/SSIP_coworking_space.pdf",
  },
];

const SSIPPage = () => {
  return (
    <Layout
      title="Student Startup & Innovation"
      breadcrumb={[
        { name: "Home", path: "/" },
        { name: "Student Startup & Innovation" },
      ]}
    >
      <div className="coe-page">
        {/* Static Description */}
        <div className="coe-page-data">
          <div className="coe-about">
<h3 className="coe-info-title">About Student Startup and Innovation Policy</h3>
    <p className="coe-desc">
      The Government of Gujarat has developed a policy for providing assistance to Startups/Innovation. Under this scheme, any individual/group of individuals having an innovative idea/concept will be eligible and/or Universities/education institutions, Incubation Centers/PSUs/R&amp;D Institutions/Private and other establishments will be eligible as an institution to support and mentor innovators as approved by the Committee. Startups in an economy's technology sectors is an important indicator of technological performance for several reasons.
    </p>
<h3 className="coe-info-title">Key Objective of SSIP</h3>
    <ul>
      <li>Developing Innovation &amp; Preincubation Ecosystem Support (IPIES) for students</li>
      <li>Creating environment for creativity</li>
      <li>Build internal capacity of educational institutions and key components of the innovation ecosystem</li>
      <li>Create pathways for mind to market by harnessing and hand holding projects/research/innovation/ideas of students in Gujarat</li>
      <li>Creating and facilitating regional innovation efforts in state</li>
      <li>Create a common platform to showcase, support and upscale innovations</li>
    </ul>
<h3 className="coe-info-title">Invention@ GEC Bhavnagar</h3>
    <ul>
      <li>Scout best innovation/projects annually that have potential to be taken further.</li>
      <li>Mobilize existing available resources for prototyping/IPR support with universities/state government and link such resources to students.</li>
      <li>Create basic IPR and prototyping support to student projects and allow innovative students to utilize existing labs and workshops to develop proof of concept.</li>
      <li>Undertake culture building activities such as workshops, hackathons, etc. frequently.</li>
    </ul>
            <h3 className="coe-info-title">Formation</h3>
            <p className="coe-desc">
            <table border="1" cellpadding="5" cellspacing="0">
    <tr>
        <th>Sr. No.</th>
        <th>Name</th>
        <th>Designation</th>
        <th>Department</th>
        <th>Role</th>
        <th>Email ID</th>
        <th>Contact Number</th>
    </tr>
    <tr>
        <td>1</td>
        <td>Dr. N. N. Pandya</td>
        <td>Assistant Professor</td>
        <td>Humanities and Science</td>
        <td>Convener</td>
        <td>nnpandya.has@gecbhavnagar.ac.in</td>
        <td>9898312203</td>
    </tr>
    <tr>
        <td>2</td>
        <td>S. R. Garasiya</td>
        <td>Assistant Professor</td>
        <td>IT Department</td>
        <td>Member</td>
        <td>srgarasiya.it@gecbhavnagar.ac.in</td>
        <td>9904405914</td>
    </tr>
</table>

</p>
         </div>
        </div>
<div className="coe-labs" id="newsletters">
          <h3 className="coe-info-title">SSIP 2.0 OFFICE ORDER 2024</h3>
          <div className="coe-labs-list">
            <ActivityCard activities={ssipData2} />
          </div>
          
        </div>
        <div className="coe-labs" id="newsletters">
          <h3 className="coe-info-title">SSIP Coworking Space at GEC, Bhavnagar</h3>
          <div className="coe-labs-list">
            <ActivityCard activities={ssipData3} />
          </div>
          
        </div>
<div className="coe-labs" id="newsletters">
          <h3 className="coe-info-title">Acctivities</h3>
          <div className="coe-labs-list">
            <ActivityCard activities={ssipData} />
          </div>
          
        </div>
       
      </div>
    </Layout>
  );
};

export default SSIPPage;
