// newsletter.js
import Layout from "components/layout/layout";
import ActivityCard from "components/activity_card/ActivityCard";
import "./ncc.css";

const nccData = [
  {
    title: "Details of Events and Activities carried out by NCC unit of GEC bhavnagar",
    description:
      "",
    images: ["/assets/ncc/a1i1.jpeg","/assets/ncc/a1i2.jpeg","/assets/ncc/a1i3.jpeg","/assets/ncc/a1i4.jpeg","/assets/ncc/a1i5.jpeg","/assets/ncc/a1i6.jpeg","/assets/ncc/a1i7.jpeg","/assets/ncc/a1i8.jpeg","/assets/ncc/a1i9.jpeg","/assets/ncc/a1i10.jpeg","/assets/ncc/a1i11.jpeg","/assets/ncc/a1i12.jpeg","/assets/ncc/a1i13.jpeg","/assets/ncc/a1i14.jpeg","/assets/ncc/a1i15.jpeg"],
    report: "/assets/ncc/ncc1.pdf",
  },
  {
    title: "NCC Naval Camp Success for GEC Bhavnagar Cadets",
    description:
      "",
    images: ["/assets/ncc/a2i1.jpeg","/assets/ncc/a2i2.jpeg","/assets/ncc/a2i3.jpeg","/assets/ncc/a2i4.jpeg"],
    report: "/assets/ncc/ncc2.pdf",
  },
];

const NCCPage = () => {
  return (
    <Layout
      title="NCC"
      breadcrumb={[
        { name: "Home", path: "/" },
        { name: "NCC" },
      ]}
    >
      <div className="coe-page">
        {/* Static Description */}
        <div className="coe-page-data">
          <div className="coe-about">
            <h3 className="coe-info-title">About NCC at Government Engineering College, Bhavnagar</h3>
            <p className="coe-desc">
 The National Cadet Corps (NCC) is a youth organization in India that provides military training to school and college students. It is a tri-services organization, comprising the Army, Navy, and Air Force wings. The NCC aims to develop character, discipline, leadership, and a secular outlook among the youth of India. It also provides a platform for young people to learn about the armed forces and consider a career in the military. The NCC Naval Wing not only prepares cadets for potential careers in the armed forces but also equips them with valuable life skills that contribute to their overall personal and professional development. It prepares naval officers right from the school/college level. In GEC
Bhavnagar, NCC Naval Unit (3rd Gujarat Naval Unit) was established in 2021 in GEC Bhavnagar. Our NCC unit is actively participating in to various NCC activities under the
guidance of Mr. Viral R. Bhatt, ( C.T. O. of the unit), Dr. Parth Trivedi, and Dr. Gunjan Ranabhatt. Many students have selected for the national camps From GEC BHAVNAGAR Naval unit.
            </p>
          </div>
        </div>
        {/* Activity Cards Displaying Newsletter Items */}
        <div className="coe-labs" id="newsletters">
          <h3 className="coe-info-title">Activities</h3>
          <div className="coe-labs-list">
            <ActivityCard activities={nccData} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NCCPage;
