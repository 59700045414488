// newsletter.js
import Layout from "components/layout/layout";
import ActivityCard from "components/activity_card/ActivityCard";
import "./brochure.css";

const brochureData = [
  {
    title: "GEC BHAVNAGAR INFORMATION BROCHURE 2024",
    description:
      "",
    images: ["/assets/brochure/b1.png"],
    report: "https://drive.google.com/file/d/1rDEx-QGMxk6mKlDUjp2FM3i0hKxlG83Z/view?usp=drive_link",
  },
  {
    title: "GEC BHAVNAGAR INFORMATION BROCHURE 2023",
    description:
      "",
    images: ["/assets/brochure/b2.png"],
    report: "https://drive.google.com/file/d/1owN-1K_xrvq4H_SI2kLYbnhf4R4_rYWH/view?usp=drive_link",
  },
  {
    title: "GEC BHAVNAGAR INFORMATION BROCHURE 2022",
    description:
      "",
    images: ["/assets/brochure/b3.png"],
    report: "https://drive.google.com/file/d/1KLjxHy0NXsCO1e8f90eg8TrOF19Hgkx3/view?usp=drive_link",
  },
  {
    title: "GEC BHAVNAGAR INFORMATION BROCHURE 2021",
    description:
      "",
    images: ["/assets/brochure/b4.png"],
    report: "https://drive.google.com/file/d/1ue29Qdj4wEVe2JsQh0cV4390HMIl6LLx/view?usp=drive_link",
  },
];

const NewsletterPage = () => {
  return (
    <Layout
      title="Information Brochure"
      breadcrumb={[
        { name: "Home", path: "/" },
        { name: "Information Brochure" },
      ]}
    >
      <div className="coe-page">
        {/* Static Description */}
        
        {/* Activity Cards Displaying Newsletter Items */}
        <div className="coe-labs" id="newsletters">
          <h3 className="coe-info-title">Information Brochure</h3>
          <div className="coe-labs-list">
            <ActivityCard activities={brochureData} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NewsletterPage;
