import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./college_info.css";

import { Link } from "react-router-dom";

const CollegeInfo = () => {
    return (
        <section className="clg-info">
            <div className="carousel-container">
                {
                    
                }
                <HomeGallery />
            </div>
            <div className="clg-info-bar">
                <Link to="/about/college" className="clg-info-bar-content">
                    <div className="info-content-icon">
                        <i className="las la-university"></i>
                    </div>
                    <div className="info-content-title">
                        <h3>College Info</h3>
                    </div>
                    <div className="info-content-text">
                        <p>
                          GEC Bhavnagar is a state-governed educational institute of Bhavnagar, Gujrat,India.
                        </p>
                    </div>
                </Link>
                <Link to="/show/news" className="clg-info-bar-content">
                    <div className="info-content-icon">
                        <i className="las la-newspaper"></i>
                    </div>
                    <div className="info-content-title">
                        <h3>News</h3>
                    </div>
                    <div className="info-content-text">
                        <p>See the latest news and events from the college</p>
                    </div>
                </Link>
                <Link to="/placement/about" className="clg-info-bar-content">
                    <div className="info-content-icon">
                        <i className="las la-chalkboard-teacher"></i>
                    </div>
                    <div className="info-content-title">
                        <h3>Placement Info</h3>
                    </div>
                    <div className="info-content-text">
                        <p>
                            Get the latest information related to the placement
                        </p>
                    </div>
                </Link>
                <Link to="/" className="clg-info-bar-content">
                    <div className="info-content-icon">
                        <i className="las la-globe"></i>
                    </div>
                    <div className="info-content-title">
                        <h3>Campus Tour</h3>
                    </div>
                    <div className="info-content-text">
                        <p>
                            Checkout the campus tour and get to know the college
                        </p>
                    </div>
                </Link>
            </div>
        </section>
    );
};

export default CollegeInfo;

const HomeGallery = () => {
    return (
        <Carousel
            className="gallery"
            autoPlay={true}
            infiniteLoop={true}
            showThumbs={false}
            renderArrowNext={() => null}
            renderArrowPrev={() => null}
            swipeable={true}
        >
            <div className="gallery-img">
                <img src="../../assets/events/1.png" alt="clg-event" />
            </div>
            <div className="gallery-img">
                <img src="../../assets/events/2.png" alt="clg-event" />
            </div>
            <div className="gallery-img">
                <img src="../../assets/events/3.png" alt="clg-event" />
            </div>
            <div className="gallery-img">
                <img src="../../assets/events/4.png" alt="clg-event" />
            </div>
            <div className="gallery-img">
                <img src="../../assets/events/5.png" alt="clg-event" />
            </div>
            
        </Carousel>
    );
};
