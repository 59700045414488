import React, { useEffect } from "react";
import "./about_college.css";

import logo from "assets/logooo.png";
import pc from "assets/DrMGBhatt.png";
import bg from "assets/clg.jpg";
import { motion, useAnimation } from "framer-motion";
import { useState } from "react";

const AboutCollege = ({ data }) => {

    const parallexHandler = () => {
        const parallex = document.getElementById("parallex");
        const h = window.innerHeight / 2;
        let y = parallex.getBoundingClientRect().top;
        y = 10 - (y - h) * 0.5;
        parallex.style.transform = `translate3d(0,${y}px,0)`;
    };

    useEffect(() => {
        window.addEventListener("scroll", parallexHandler);

        return () => {
            window.removeEventListener("scroll", parallexHandler);
        };
    }, []);

    return (
        <>
            <div className="about-college">
                <div className="about-college-title">
                    <h2 className="title-bold">
                        A Few Words About
                        <br />
                        GEC Bhavnagar
                    </h2>
                </div>

                <div className="about-college-content">
                    <div className="about-content-wrapper">
                        <div className="about-content">
                            <SeeMoreText text={data.about} />
                        </div>
                        <div className="about-img-wrapper gec">
                            <img
                                src={logo}
                                className="about-img"
                                alt="gecbvn-logo"
                            />
                        </div>
                    </div>
                    <div className="about-content-wrapper">
                        <div className="about-img-wrapper pc">
                            <img
                                src={pc}
                                className="about-img"
                                alt="DrMGBhatt"
                            />
                            <span>
                                <h6>Principal</h6>
                                <h6>Prof. (Dr.) M. G. Bhatt</h6>
                                <a href = "assets/Profile_DrMGBhatt.pdf" target = "_blank"><h6>Profile</h6> </a>
                            </span>
                        </div>
                        <div className="about-content">
                            <SeeMoreText text={data.message} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="vision-mission parallex-wrapper">
                <div className="parallex" id="parallex">
                    <img src={bg} alt="" height="100%" />
                </div>
                <div className="parallex-content"></div>
            </div>
        </>
    );
};

export default AboutCollege;

const variants = {
    hidden: {
        opacity: 1,
        height: 100,
        overflow: "hidden",
    },
    visible: {
        opacity: 1,
        overflow: "visible",
        height: "100%",
        transition: {
            type: "spring",
            duration: 0.3,
            staggerChildren: 0.1,
        },
    },
};

const SeeMoreText = ({ text = "" }) => {
    const controls = useAnimation();
    const [isCollapsed, setIsCollapsed] = useState(true);
    const handleSeeMore = () => {
        if (isCollapsed) {
            controls.start("visible").then(() => {
                setIsCollapsed(!isCollapsed);
            });
        } else {
            controls.start("hidden").then(() => {
                setIsCollapsed(!isCollapsed);
            });
        }
    };
    return (
        <>
            <motion.div
                variants={variants}
                initial="hidden"
                animate={controls}
                className="see-more-text"
            >
               <p>{text}</p>
            </motion.div>
            <div className="header-btn" onClick={handleSeeMore}>
                See {isCollapsed ? "More" : "Less"}{" "}
                <i className="fas fa-arrow-right"></i>
            </div>
        </>
    );
};
