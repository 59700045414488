import DataProvider from "hoc/data_provider";

import { NavLink, useParams } from "react-router-dom";

import React, { useState } from "react";

import Layout from "components/layout/layout";
import FacultyLoader from "components/loader/FacultyLoader";

import { useEffect } from "react";

import "./all_faculties.css";
import "./undraw_profile_pic_ic5t.svg";
import axios from "axios";

const departments = [
    {
        key:"All",
        name:"All Faculty"
    },
    {
        key:"COMPUTER ENGINEERING",
        name:"Computer Engineering"
    },
    {
        key:"INFORMATION TECHNOLOGY",
        name:"Information Technology"
    },
    {
        key:"ELECTRONICS & COMM. ENGG.",
        name:"Electronics & Communication Engineering"
    },
    {
        key:"CIVIL ENGINEERING",
        name:"Civil Engineering"
    },
    {
        key:"MECHANICAL ENGINEERING",
        name:"Mechanical Engineering"
    },
    {
        key:"PRODUCTION ENGINEERING",
        name:"Production Engineering"
    },
    {
        key:"INFORMATION AND COMMUNICATION TECHNOLOGY",
        name:"Information and Communication Technology"
    },
    //{
    //    key:"ELECTRONICS AND INSTRUMENTATION ENGINEERING",
    //    name:"Electronics and Instrumentation  Engineering"
    //},
    {
        key:"APPLIED MECHANICS",
        name:"Applied Mechanics"
    },
    // {
    //     key:"CENTER OF EXCELLENCE",
    //     name:"Center Of Excellence"
    // },
    {
        key:"Humanities and Science Department",
        name:"Humanities and Science Department"
    }
]

const AllFacultiesPage = ( dept ) => {
    return (
        <DataProvider
            component={Faculties}
            endPoint="/web/faculties"
            loader={FacultyLoader}
        />
    );
};
export default AllFacultiesPage;

const Faculties = ({ data }) => {

    const filteredData = data.filter((faculty) => {
        return faculty.profileVisiblity;
    });

    const [filteredFaculty, setFaculty] = useState(filteredData);
    const path = window.location.pathname.slice(9);

    let keys = {
        "c.e": "COMPUTER ENGINEERING",
        "ci.e": "CIVIL ENGINEERING",
        "i.t": "INFORMATION TECHNOLOGY",
        "e&c.e": "ELECTRONICS & COMM. ENGG.",
        "m.e": "MECHANICAL ENGINEERING",
        "p.e": "PRODUCTION ENGINEERING",
        "a.m": "APPLIED MECHANICS",
        "co.e": "CENTER OF EXCELLENCE",
        "ict": "INFORMATION AND COMMUNICATION TECHNOLOGY",
        "eie": "ELECTRONICS AND INSTRUMENTATION ENGINEERING",
        "has": "Humanities and Science Department"        
    }
    const [activeDepartment, setActiveDepartment] = useState(!path ? "All" : keys[path]);
    
    const calcStats = () => {
    console.log("Active Department:", activeDepartment);
    console.log("Available Departments:", data.map(faculty => faculty.department_name));
        let filteredFaclt = data.filter((filteredFaculty) => {
            if (activeDepartment === "All") {
                return true;
            }
            return filteredFaculty.department_name === activeDepartment;
        });
        
        setFaculty(filteredFaclt);
    };
    

    useEffect(() => {
        calcStats();
    }, [activeDepartment]);

    return (
        <Layout
            title="Faculties"
            breadcrumb={[
                { name: "Home", path: "/" },
                {
                    name: "Faculties",
                },
            ]}
        >
            <div className="all-faculties-page">
                <div className="departments row">

                    {departments.map((department, id) => {
                        return (
                            <p
                                key={id}
                                className={
                                    activeDepartment === department.key
                                        ? "department-name active"
                                        : "department-name"
                                }
                                onClick={() =>
                                    setActiveDepartment(department.key)
                                }
                            >
                                {department.name}
                            </p>
                        );
                    })}

                </div>
                    
                <div className="faculties row r-c-x">
                    {filteredFaculty.map((faculty, idx) => {
                        return <FacultyCard key={idx} faculty={faculty} />;
                    })}
                </div>
            </div>
        </Layout>
    );
};

const FacultyCard = ({ faculty }) => {

    if(!faculty.profileVisiblity || !faculty.personal_details) return <></>

    const {
        first_name,
        last_name,
        prefix,
        area_of_interest,
        designation,
        image,
    } = faculty.personal_details;

    const { department_name, _id } = faculty;

    const name = prefix + " " + first_name + " " + last_name;
    let img;
    if (image) {
        img = `${axios.defaults.baseURL}/download/profiles/${image}`;
    }

    return (
        <div className="faculty">
            <NavLink to={`/faculty/${_id}`} target="_blank">
                <div className="image">
                    <img src={img} alt="profile" loading="lazy" />
                </div>
                <div className="data column c-c-y">
                    <p className="name">{name}</p>
                    {/* <p className="title">
                        phD(university of undraw at knoxville)
                    </p> */}
                    <div className="designation">
                        <strong>{designation}</strong>
                    </div>
                    <div className="branch">
                        <strong>{department_name}</strong>
                    </div>
                    <div className="intreset">
                        <p>{area_of_interest}</p>
                    </div>
                </div>
            </NavLink>
        </div>
    );
};
