import "./digital_info.css";

import DataProvider from "hoc/data_provider";
import { NavLink } from "react-router-dom";
import useMetadata from "hooks/useMetadata";
import NewsBoardLoader from "components/loader/NewsBoardLoader";

const DigitalInfo = () => {
    return (
        <section className="digital-info">
            <div className="digital-info-title">
                <h2 className="title-bold">
                    What's happening at
                    <br />
                    GEC Bhavnagar?
                </h2>
            </div>
            <div className="info-container">
                <div className="notice-container">
                    <DataProvider
                        endPoint="/web/notice"
                        component={NewsEventBox}
                        title="Notice Board"
                        icon="fas fa-exclamation"
                        type="notice"
                        loader={NewsBoardLoader}
                    />
                </div>
                <div className="news-events-container">
                    <DataProvider
                        endPoint="/web/news"
                        component={NewsEventBox}
                        title="Latest News"
                        icon="fas fa-newspaper"
                        type="news"
                        loader={NewsBoardLoader}
                    />

                    <DataProvider
                        endPoint="/web/events"
                        component={NewsEventBox}
                        title="Upcoming Events"
                        icon="fas fa-calendar-day"
                        type="events"
                        loader={NewsBoardLoader}
                    />
                </div>
            </div>
        </section>
    );
};

export const NewsEventBox = ({ title, icon, data, type, ...other }) => {

    // filter data according to  current date
    data = data.filter((v) => {
        var today = new Date();
        return Date.parse(v.date) >= today || type === "news" || type === "notice";
    });

    return (
        <div className={`info-box column ${type}-box`} {...other}>
            <div className="box-header row r-c-y">
                <div className="icon-title row r-c-y r-c-x">
                    <div className="header-icon">
                        <i className={icon}></i>
                    </div>
                    <h4 className="header-title">{title}</h4>
                </div>

                <NavLink className="header-btn" to={`/show/${type}`}>
                    See All
                </NavLink>
            </div>

            <div className="info-box-content">
                {
                    data.length > 0 ? (Array.from(data, (v, i) => {
                        return <NewsEventRow key={i} data={data[i]} type={type} />;
                    })) : (<p className="no-data">Upcoming event details will be updated here...</p>)
                }
            </div>
        </div>
    );
};

const NewsEventRow = ({ data, type }) => {
    const { proxy } = useMetadata();
    let endPoint = null;
    if (data.file) {
        endPoint = `${proxy}/download/files/${data.file}`;
    }
    var today = new Date();
    return Date.parse(data.date) >= today || type !== "events" ? (
        <div className="info-box-data row r-c-y">
            <div className="data-icon"></div>
            <div className="data-wrapper">
                {data.file ? (
                    <a href={endPoint} className="data">
                        {data.title}
                    </a>
                ) : (
                    <p className="data">{data.title}</p>
                )}
                <p className="date-time">{data.date.substring(0, 10)}</p>
            </div>
        </div>
    ) : null;
};

export default DigitalInfo;
