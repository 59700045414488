// newsletter.js
import Layout from "components/layout/layout";
import ActivityCard from "components/activity_card/ActivityCard";
import "./assr.css";

{/*{
    title: "Organization Chart",
    description:
      "",
    images: ["/assets/adminsetup/a1.png"],
    report: "/assets/adminsetup/ANNEXURE 1.pdf",
  },
  {
    title: "Institute Body",
    description:
      "",
    images: ["/assets/adminsetup/a2.png"],
    report: "/assets/adminsetup/ANNEXURE 2.pdf",
  },*/}

const adminsetupData1 = [
  {
    title: "Organization Chart",
    description:
      "",
    images: ["/assets/adminsetup/a1.png"],
    report: "/assets/adminsetup/ANNEXURE 1.pdf",
  },
];
const adminsetupData2 = [
  {
    title: "Institute Body",
    description:
      "",
    images: ["/assets/adminsetup/a2.png"],
    report: "/assets/adminsetup/ANNEXURE 2.pdf",
  },
];
const srData = [
  
  {
    title: "GUJARAT MULKI SEVA (NOKARI NI SAMANYA SHARATO) NIYAMO, 2002",
    description:
      "",
    images: ["/assets/servicerules/sr1.png"],
    report: "/assets/servicerules/ServiceRules-1.pdf",
  },
  {
    title: "GUJARAT MULKI SEVA (FARAJ PAR JODAVANA SAMAY, RAJYETAR SEVA, BHARAT BAHAR PRATINIYUKTI, FARAJ MOKUFI ANE RUKHSAD) NIYAMO, 2002",
    description:
      "",
    images: ["/assets/servicerules/sr2.png"],
    report: "/assets/servicerules/ServiceRules-2.pdf",
  },
  {
    title: "GUJARAT MULKI SEVA (PAGAR) NIYAMO, 2002",
    description:
      "",
    images: ["/assets/servicerules/sr3.png"],
    report: "/assets/servicerules/ServiceRules-3.pdf",
  },
  {
    title: "GUJARAT MULKI SEVA (PAGAR AADHARIT BHATHTHA) NIYAMO, 2002",
    description:
      "",
    images: ["/assets/servicerules/sr4.png"],
    report: "/assets/servicerules/ServiceRules-4.pdf",
  },
  {
    title: "GUJARAT MULKI SEVA (MUSAFARI) NIYAMO, 2002",
    description:
      "",
    images: ["/assets/servicerules/sr5.png"],
    report: "/assets/servicerules/ServiceRules-5.pdf",
  },
  {
    title: "GUJARAT MULKI SEVA (RAJA) NIYAMO, 2002",
    description:
      "",
    images: ["/assets/servicerules/sr6.png"],
    report: "/assets/servicerules/ServiceRules-6.pdf",
  },
  {
    title:"GUJARAT MULKI SEVA (RAHENANK NA MAKANO MA VASVAAT) NIYAMO, 2002", 
    description:
      "",
    images: ["/assets/servicerules/sr7.png"],
    report: "/assets/servicerules/ServiceRules-7.pdf",
  },
  {
    title: "gUJARAT MULKI SEVA (PENSION) NIYAMO, 2002",
    description:
      "",
    images: ["/assets/servicerules/sr8.png"],
    report: "/assets/servicerules/ServiceRules-8.pdf",
  },
];
const casData = [
  {
    title: "CAS Procedure Instructions by CTE, Gujarat",
    description:
      "",
    images: ["/assets/cas/cas1.png"],
    report: "/assets/cas/CAS_Procedure_Instructions_by_CTE.pdf",
  },
  {
    title: "CAS Degree Resolution Dated 01/03//2024.pdf",
    description:
      "",
    images: ["/assets/cas/cas2.png"],
    report: "/assets/cas/CAS_Degree_Resolution_Dated_01_03_2024.pdf",
  },
  {
    title: "CAS User Manual for CAS Module Workflow.pdf",
    description:
      "",
    images: ["/assets/cas/cas3.png"],
    report: "/assets/cas/CAS_User_Manual_for_CAS_Module_Workflow.pdf",
  },
  {
    title: "CTE, Gujarat Circular Regarding Implementation of 360 Feedback.pdf",
    description:
      "",
    images: ["/assets/cas/cas4.png"],
    report: "/assets/cas/CTE_circular_Regarding_Implementation_of_360_Feedback.pdf",
  },
  {
    title: "360 feedback Institute Circular.pdf",
    description:
      "",
    images: ["/assets/cas/cas5.png"],
    report: "/assets/cas/360_feedback_Institute_Circular.pdf",
  },
];
const ASSRPage = () => {
  return (
    <Layout
      title="Administrative Setup and Service Rules"
      breadcrumb={[
        { name: "Home", path: "/" },
        { name: "Administrative Setup" },
      ]}
    >
      <div className="coe-page">
        {/* Static Description */}
        
        {/* Activity Cards Displaying Newsletter Items */}
        <div className="coe-labs" id="newsletters">
          <h3 className="coe-info-title">Administrative Setup</h3>
          <h3 className="coe-info-title">Organization Chart</h3>
          <img src="/assets/adminsetup/organization chart.png" alt="Organization Chart"/>
          <div className="coe-labs-list">
            <ActivityCard activities={adminsetupData1} />
          </div>
          <h3 className="coe-info-title">Institute Body</h3>
          <img src="/assets/adminsetup/institute body.png" alt="Institute Body"/>
          <div className="coe-labs-list">
            <ActivityCard activities={adminsetupData2} />
          </div>
          <h3 className="coe-info-title">Service Rules</h3>
          <div className="coe-labs-list">
            <ActivityCard activities={srData} />
          </div>
          <h3 className="coe-info-title">Career Advancement Scheme</h3>
          <div className="coe-labs-list">
            <ActivityCard activities={casData} />
          </div>
          <a href = "https://dte.gujarat.gov.in/government-resolution" target = "_blank"><h3 className="coe-info-title">Click Here for Government Resolutions</h3></a>
        </div>
      </div>
    </Layout>
  );
};

export default ASSRPage;
