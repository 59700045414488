// newsletter.js
import Layout from "components/layout/layout";
import ActivityCard from "components/activity_card/ActivityCard";
import "./sgrc.css";

const newsletterData = [
  
];

const SGRCPage = () => {
  return (
    <Layout
      title="Student Grievance Redressal Cell"
      breadcrumb={[
        { name: "Home", path: "/" },
        { name: "Student Grievance Redressal Cell" },
      ]}
    >
      <div className="coe-page">
        {/* Static Description */}
        <div className="coe-page-data">
          <div className="coe-about">
            
            <h3 className="coe-info-title">Formation</h3>
            <p className="coe-desc">
              <table border="1" cellpadding="5" cellspacing="0">
  <thead>
    <tr>
      <th>Sr. No.</th>
      <th>Name</th>
      <th>Designation</th>
      <th>Department</th>
      <th>Role</th>
      <th>Email ID</th>
      <th>Contact Number</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1</td>
      <td>V. S. Dave</td>
      <td>Associate Professor</td>
      <td>Civil Engineering</td>
      <td>Convener</td>
      <td>vsdave.civil@gecbhavnagar.ac.in</td>
      <td>9998942010</td>
    </tr>
    <tr>
      <td>2</td>
      <td>M. H. Aghara</td>
      <td>Assistant Professor</td>
      <td>Applied Mechanics</td>
      <td>Member</td>
      <td>mhaghara.am@gecbhavnagar.ac.in</td>
      <td>9879430751</td>
    </tr>
    <tr>
      <td>3</td>
      <td>Dr. N. N. Pandya</td>
      <td>Assistant Professor</td>
      <td>Humanities and Science</td>
      <td>Member</td>
      <td>nnpandya.has@gecbhavnagar.ac.in</td>
      <td>9898312203</td>
    </tr>
    <tr>
      <td>4</td>
      <td>Raj Pandya</td>
      <td>Student</td>
      <td></td>
      <td>Member</td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>5</td>
      <td>Vanshika Mehta</td>
      <td>Student</td>
      <td></td>
      <td>Member</td>
      <td></td>
      <td></td>
    </tr>
  </tbody>
</table>
</p>
         </div>
        </div>
        
      </div>
    </Layout>
  );
};

export default SGRCPage;
