// newsletter.js
import Layout from "components/layout/layout";
import ActivityCard from "components/activity_card/ActivityCard";
import "./csa.css";

const newsletterData = [
  
];

const SGRCPage = () => {
  return (
    <Layout
      title="Cell for Specially Abled"
      breadcrumb={[
        { name: "Home", path: "/" },
        { name: "Cell for Specially Abled" },
      ]}
    >
      <div className="coe-page">
        {/* Static Description */}
        <div className="coe-page-data">
          <div className="coe-about">
            
            <h3 className="coe-info-title">Formation</h3>
            <p className="coe-desc">

<table border="1" cellpadding="5" cellspacing="0">
    <tr>
        <th>Sr. No.</th>
        <th>Name</th>
        <th>Designation</th>
        <th>Department</th>
        <th>Role</th>
        <th>Email ID</th>
        <th>Contact Number</th>
    </tr>
    <tr>
        <td>1</td>
        <td>A. D. Dhandhukia</td>
        <td>Assistant Professor</td>
        <td>Humanities and Science</td>
        <td>Convener</td>
        <td>addhandhukia.has@gecbhavnagar.ac.in</td>
        <td>9426978747</td>
    </tr>
    <tr>
        <td>2</td>
        <td>K. D. Chavda</td>
        <td>Assistant Professor</td>
        <td>ICT Department</td>
        <td>Member</td>
        <td>kdchavda.ict@gecbhavnagar.ac.in</td>
        <td>9825505028</td>
    </tr>
</table>


</p>
         </div>
        </div>
        
      </div>
    </Layout>
  );
};

export default SGRCPage;
