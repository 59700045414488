// newsletter.js
import Layout from "components/layout/layout";
import ActivityCard from "components/activity_card/ActivityCard";
import "./iqac.css";


{/*{
    title: "Tecknovation Volume-V Issue-I (January-2024 to June-2024)",
    description:
      "",
    images: ["/assets/newsletter/images/t8.png"],
    report: "https://drive.google.com/file/d/1hs_8r6XB59F2SN5PG_4c-oi7S7ThocgO/view?usp=sharing",
  },*/}
const iqacData1 = [
  {
    title: "Formation of IQAC dated 19/07/2024",
    description:
      "",
    images: ["/assets/iqac/iqacformation3.png"],
    report: "/assets/iqac/iqacformation3.pdf",
  },
  {
    title: "Formation of IQAC dated 28/07/2022",
    description:
      "",
    images: ["/assets/iqac/iqacformation2.png"],
    report: "/assets/iqac/iqacformation2.pdf",
  },
  
  {
    title: "Formation of IQAC dated 08/03/2021",
    description:
      "",
    images: ["/assets/iqac/iqacformation1.png"],
    report: "/assets/iqac/iqacformation1.pdf",
  },
];
const iqacData2 = [
  {
    title: "IQAC Minutes of Meeting dated 31/01/2025",
    description:
      "",
    images: ["/assets/iqac/iqacmom4.png"],
    report: "/assets/iqac/iqacmom4.pdf",
  },
  {
    title: "IQAC Minutes of Meeting dated 19/08/2023",
    description:
      "",
    images: ["/assets/iqac/iqacmom3.png"],
    report: "/assets/iqac/iqacmom3.pdf",
  },
  {
    title: "IQAC Minutes of Meeting dated 07/09/2022",
    description:
      "",
    images: ["/assets/iqac/iqacmom2.png"],
    report: "/assets/iqac/iqacmom2.pdf",
  },
  {
    title: "IQAC Minutes of Meeting dated 29/10/2021",
    description:
      "",
    images: ["/assets/iqac/iqacmom1.png"],
    report: "/assets/iqac/iqacmom1.pdf",
  },
];
const iqacData3 = [
  {
    title: "IQAC Office Order for Internal Academic Inspection",
    description:
      "",
    images: ["/assets/iqac/iqaccircular3.png"],
    report: "/assets/iqac/iqaccircular3.pdf",
  },
  {
    title: "IQAC Technical Institute Inspection Manual",
    description:
      "",
    images: ["/assets/iqac/iqaccircular2.png"],
    report: "/assets/iqac/iqaccircular2.pdf",
  },
  {
    title: "IQAC Nomination Policy 2021",
    description:
      "",
    images: ["/assets/iqac/iqaccircular1.png"],
    report: "/assets/iqac/iqaccircular1.pdf",
  },
];
const iqacData4 = [
  {
    title: "IQAC Stake Holder Meet (Mechanical Engineering Department)",
    description:
      "",
    images: ["/assets/iqac/iqacevent3.png"],
    report: "/assets/iqac/iqacevent3.pdf",
  },
  {
    title: "IQAC Awareness Seminar on NBA, NIRF and GSIRF",
    description:
      "",
    images: ["/assets/iqac/iqacevent2.png"],
    report: "/assets/iqac/iqacevent2.pdf",
  },
  {
    title: "IQAC Seminar on ABC ID Implementation",
    description:
      "",
    images: ["/assets/iqac/iqacevent1.png"],
    report: "/assets/iqac/iqacevent1.pdf",
  },
];

const IQACPage = () => {
  return (
    <Layout
      title="IQAC"
      breadcrumb={[
        { name: "Home", path: "/" },
        { name: "IQAC" },
      ]}
    >
      <div className="coe-page">
        {/* Static Description */}
        <div className="coe-page-data">
          <div className="coe-about">
            
    <h3 className="coe-info-title">IQAC Objective</h3>
    <p className="coe-desc">
      The primary aim of IQAC is:
    </p>
    <ul>
      <li>To develop a system for conscious, consistent, and catalytic action to improve the academic and administrative performance of the institution.</li>
      <li>To promote measures for institutional functioning towards quality enhancement through internalization of quality culture and institutionalization of best practices.</li>
    </ul>
    <h3 className="coe-info-title">IQAC Responsibilities</h3>
    <ul>
      <li>Ensuring timely, efficient, and progressive performance of academic, administrative, and financial tasks.</li>
      <li>The relevance and quality of academic and research programmes.</li>
      <li>Equitable access to and affordability of academic programmes for various sections of society.</li>
      <li>Optimization and integration of modern methods of teaching and learning.</li>
      <li>The credibility of evaluation procedures.</li>
      <li>Ensuring the adequacy, maintenance, and proper allocation of support structure and services.</li>
      <li>Insist the faculty to join faculty development training and industrial training.</li>
      <li>Documentation of the various programmes/activities leading to quality improvement.</li>
      <li>To keep track of the departments that are accredited by the NBA and ensure that those departments maintain the standards of the NBA.</li>
      <li>To complete the NBA pre-qualifier every year for the departments not accredited by the NBA and notify the principal and CDC when any department becomes eligible for accreditation.</li>
    </ul>
    
          </div>
        </div>
        {/* Activity Cards Displaying Newsletter Items */}
        <div className="coe-labs" id="newsletters">
          <h3 className="coe-info-title">Formation of IQAC</h3>
          <div className="coe-labs-list">
            <ActivityCard activities={iqacData1} />
          </div>
          <h3 className="coe-info-title">IQAC Meetings and MoM</h3>
          <div className="coe-labs-list">
            <ActivityCard activities={iqacData2} />
          </div>
          <h3 className="coe-info-title">IQAC Circulars</h3>
          <div className="coe-labs-list">
            <ActivityCard activities={iqacData3} />
          </div>
          <h3 className="coe-info-title">IQAC Events</h3>
          <div className="coe-labs-list">
            <ActivityCard activities={iqacData4} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IQACPage;
