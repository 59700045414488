import Heading from "components/heading/Heading";

const WorkExperience = ({ data }) => {
    return (
        <div className="work-experience details-section" id="work">
            <Heading>Work Experience</Heading>
            <div className="information">
                <div className="table-wrapper">
                    <div className="table-wrapper">
                        <table className="data-table">
                            <thead>
                                <tr>
                                    <th>Sr No.</th>
                                    <th>
                                        <strong>Name of the Organization</strong>
                                    </th>
                                    <th>
                                        <strong>Designation</strong>
                                    </th>
                                    <th>
                                        <strong>Start</strong>
                                    </th>
                                    <th>
                                        <strong>End</strong>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {data.map((v, i) => {
                                    return (
                                        <tr key={v.name}>
                                            <td>{i + 1}</td>
                                            <td>{v.name}</td>
                                            <td>{v.designation}</td>
                                            <td>
                                                {v.start_date.substring(0, 10)}
                                            </td>
                                            <td>
                                                {v.end_date
                                                    ? v.end_date.substring(
                                                          0,
                                                          10
                                                      )
                                                    : "Till Date"}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorkExperience;
